import React from 'react';

import styles from './Statement.module.scss';

export const Statement: React.FC = () => {
    return (
        <div className={styles.statementContainer}>
            <p className={styles.statement}>
                The most stylish learning experience. Ever.
            </p>
        </div>
    );
};
