import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

export const initializeGA = () => {
    ReactGA.initialize('G-JDJCC1X3LL');
};

export const usePageTracking = () => {
    const location = useLocation();

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: location.pathname + location.search,
        });

        if (window.location.hostname === 'localhost') {
            ReactGA.event({
                category: 'Debug',
                action: 'Pageview',
                label: 'Debug mode',
                nonInteraction: true,
            });
        }
    }, [location.pathname, location.search]);
};

export const trackEvent = (category: string, action: string, label: string) => {
    ReactGA.event({ category, action, label });
};
