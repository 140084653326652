import React, { useEffect } from 'react';

import { Typography } from '../../../common';
import styles from './LoadingModal.module.scss';

interface Props {
    header: string;
    text: string;
    setPageLoader?: any;
}

export const LoadingModal: React.FC<Props> = ({
    header,
    text,
    setPageLoader,
}: Props) => {
    useEffect(() => {
        const timer = setTimeout(() => setPageLoader(true), 0);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className={styles.loadingModalContainer}>
            <div className={styles.loadingTextBox}>
                <Typography variant="title-xl-semibold">{header}</Typography>
                <Typography variant="title-sm-regular">{text}</Typography>
            </div>
            <div className={styles.loadingBar}></div>
        </div>
    );
};
