import React, { useState } from 'react';
import classNames from 'classnames';
import { Document, Page } from 'react-pdf';
import ximg from '../../../../assets/Practice/x-close.png';
import styles from './papermodal.module.scss';

interface ModalProps {
    chk: (value: boolean) => void;
    pdf?: string; // Path to your PDF file
    pdf2?: string; // Path to your PDF file
    split?: boolean;
    parentClassname?: string;
}

export const PaperModal: React.FC<ModalProps> = ({
    chk,
    pdf,
    pdf2,
    split,
    parentClassname,
}) => {
    const [numPages, setNumPages] = useState<number>();
    const [numPages2, setNumPages2] = useState<number>();

    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setNumPages(numPages);
    }

    function onDocumentLoadSuccess2({ numPages }: { numPages: number }): void {
        setNumPages2(numPages);
    }

    return (
        <div className={classNames(styles.modalBackground, parentClassname)}>
            <div className={styles.ModelPrent}>
                <div className={styles.pdfModal}>
                    <div className={styles.titleCloseBtn}>
                        <button onClick={() => chk(false)}>
                            {' '}
                            <img src={ximg} alt="ximage" />{' '}
                        </button>
                    </div>
                    {!split ? (
                        <div className={styles.pdfSection}>
                            <Document
                                file={pdf}
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                {Array.from(
                                    { length: Math.floor(Number(numPages)) },
                                    (_, idx) => ++idx
                                ).map((pageNum, i) => (
                                    <Page
                                        devicePixelRatio={
                                            window.devicePixelRatio
                                        }
                                        className={styles.customStyle}
                                        width={800}
                                        pageNumber={pageNum}
                                        key={i}
                                    />
                                ))}
                            </Document>
                        </div>
                    ) : (
                        <div className={styles.pdfSection}>
                            <div
                                className={styles.pdf}
                                style={{
                                    overflow: 'auto',
                                }}
                            >
                                <Document
                                    file={pdf}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                >
                                    {Array.from(
                                        {
                                            length: Math.floor(
                                                Number(numPages)
                                            ),
                                        },
                                        (_, idx) => ++idx
                                    ).map((pageNum, i) => (
                                        <Page
                                            devicePixelRatio={
                                                window.devicePixelRatio
                                            }
                                            className={styles.customStyle}
                                            // width={800}
                                            pageNumber={pageNum}
                                            key={i}
                                        />
                                    ))}
                                </Document>
                            </div>

                            <div
                                className={styles.pdf}
                                style={{
                                    overflow: 'auto',
                                }}
                            >
                                <Document
                                    file={pdf2}
                                    onLoadSuccess={onDocumentLoadSuccess2}
                                >
                                    {Array.from(
                                        {
                                            length: Math.floor(
                                                Number(numPages2)
                                            ),
                                        },
                                        (_, idx) => ++idx
                                    ).map((pageNum, i) => (
                                        <Page
                                            devicePixelRatio={
                                                window.devicePixelRatio
                                            }
                                            className={styles.customStyle}
                                            // width={800}
                                            pageNumber={pageNum}
                                            key={i}
                                        />
                                    ))}
                                </Document>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
