import React, { useState } from 'react';

import { Button, Typography } from '../../../common';
import { PaperGenerationModals } from '../PaperGenerationModals';
import { FilePlus } from '../../../assets';

import styles from './PaperGeneration.module.scss';

interface Props {
    onGenerate: (mockPaperLabel: string) => void;
}

export const PaperGeneration: React.FC<Props> = ({ onGenerate }: Props) => {
    const [modalFlowOpened, setModalFlowOpened] = useState<boolean>(false);

    const toggleModalFlow = () => {
        setModalFlowOpened((prevVal) => !prevVal);
    };

    return (
        <div className={styles.paperGenerationContainer}>
            <div className={styles.actionBtns}>
                <Button
                    styleType="primary"
                    className={styles.btn}
                    onClick={toggleModalFlow}
                >
                    <FilePlus className={styles.icon} />
                    <Typography variant="text-md-semibold">
                        Generate a mock paper
                    </Typography>
                </Button>
            </div>
            {modalFlowOpened && (
                <PaperGenerationModals
                    onGenerate={onGenerate}
                    closeFn={toggleModalFlow}
                />
            )}
        </div>
    );
};
