import { Courses_available } from './constant';
import {
    Chapter,
    CompetitiveQuiz,
    competitiveQuizzes,
    Course,
    courses,
    papers,
    RelatedQInfo,
    TopicalQuiz,
    topicalQuizzes,
} from './data';

export const checkisUserPremium = (
    userCourses: string[],
    course: string | undefined,
    free: boolean = false
) => {
    for (const c of userCourses) {
        if (c == course) {
            return true;
        }
    }
    return free || false;
};

export const getCourse = (course?: string): Course | undefined => {
    if (course && Courses_available.includes(course)) {
        return courses[course];
    } else {
        return undefined;
    }
};

export const getChapter = (
    course?: string,
    chapter?: string
): Chapter | null => {
    if (course && chapter && Courses_available.includes(course)) {
        const idx = parseInt(chapter) - 1;
        return courses[course].chapters[idx];
    } else {
        return null;
    }
};

export const getRelatedQuestionsInfo = (
    course?: string,
    chapter?: string,
    lesson?: string
): RelatedQInfo | null => {
    if (course && Courses_available.includes(course) && chapter && lesson) {
        const chap_idx = parseInt(chapter) - 1;
        const lesson_idx = parseInt(lesson) - 1;
        const curriculum = courses[course].curriculum;
        const lessonInfo =
            courses[course].chapters[chap_idx].lessons[lesson_idx];
        return {
            curriculum: curriculum,
            title: lessonInfo.description,
            relatedQlink: lessonInfo.related_qlink,
        };
    } else {
        return null;
    }
};

export const getLesson = (
    course?: string,
    chapter?: string,
    lesson?: string
) => {
    if (course && Courses_available.includes(course) && chapter && lesson) {
        const chap_idx = parseInt(chapter) - 1;
        const lesson_idx = parseInt(lesson) - 1;
        return courses[course].chapters[chap_idx].lessons[lesson_idx];
    } else {
        return null;
    }
};

export const getSublesson = (
    course?: string,
    chapter?: string,
    lesson?: string,
    sublesson?: string
) => {
    if (
        course &&
        Courses_available.includes(course) &&
        chapter &&
        lesson &&
        sublesson
    ) {
        const chap_idx = parseInt(chapter) - 1;
        const lesson_idx = parseInt(lesson) - 1;
        const sub_idx = parseInt(sublesson) - 1;
        return courses[course].chapters[chap_idx].lessons[lesson_idx]
            .sublessons[sub_idx];
    } else {
        return null;
    }
};

export const getPapers = (
    curriculum: string,
    level: string,
    subject: string
) => {
    return papers[curriculum][level][subject];
};

export const validatePapers = (
    curriculum: string,
    level: string,
    subject: string
) => {
    if (
        papers[curriculum] &&
        papers[curriculum][level] &&
        papers[curriculum][level][subject]
    ) {
        // All levels exist
        return true;
    } else {
        // One or more levels do not exist, return false
        return false;
    }
};

export const validatePaper = (
    curriculum: string,
    level: string,
    subject: string,
    paperIndex: string
) => {
    if (
        papers[curriculum] &&
        papers[curriculum][level] &&
        papers[curriculum][level][subject] &&
        papers[curriculum][level][subject].papers[
            Math.floor(Number(paperIndex))
        ]
    ) {
        // All levels exist
        return true;
    } else {
        // One or more levels do not exist, return false
        return false;
    }
};

// Quizzes

export const getTopicalQuizzes = (
    curriculum?: string,
    level?: string,
    subject?: string,
    topic?: string
): TopicalQuiz[] | [] => {
    if (curriculum && level && subject && topic) {
        const key = `${curriculum}:${level}:${subject}:${topic}`;
        return topicalQuizzes[key];
    } else {
        return [];
    }
};

export const getRandomQuizzes = (): CompetitiveQuiz[] | [] => {
    const n = competitiveQuizzes.length;
    const randomIndexes = new Set<number>();
    const selectedQuizzes: CompetitiveQuiz[] = [];

    while (randomIndexes.size < 50) {
        const randomIndex = Math.floor(Math.random() * n);
        randomIndexes.add(randomIndex);
    }

    // Use each unique index to select a quiz from the original list
    randomIndexes.forEach((index) => {
        selectedQuizzes.push(competitiveQuizzes[index]);
    });

    return selectedQuizzes;
};
