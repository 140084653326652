import classNames from 'classnames';
import React, { useRef, useState } from 'react';

import { Check, ChevronDown, ChevronUp } from '../../assets';

import styles from './DropdownSelect.module.scss';

export type DropdownSelections = {
    indexes: number[];
    values: string[];
};

export interface DropdownSelectProps {
    containerClassname?: string;
    className?: string;
    placeholder?: string;
    list: string[];
    // required: boolean;
    // disabled?: boolean;
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
    onSelect?: (selection: DropdownSelections) => void;
    onChange?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const DropdownSelect: React.FC<DropdownSelectProps> = ({
    // required,
    list,
    placeholder = '-',
    className,
    onSelect,
    onChange,
}) => {
    const selectionRef = useRef(null);

    const [dropdownOpened, setDropdownOpened] = useState(false);
    const [selections, setSelections] = useState<DropdownSelections>({
        indexes: [],
        values: [],
    });

    const handleDropdown = () => {
        setDropdownOpened((prevVal) => !prevVal);
    };
    const handleSelection = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        //set selection data locally
        const selectionIndex: number = Number(
            (e.target as HTMLDivElement).dataset.index
        ); // set index
        const selectionValue = (
            (e.target as HTMLDivElement).parentNode
                ?.childNodes[0] as HTMLDivElement
        ).innerText.toLowerCase(); // set value

        if (!selections.values.includes(selectionValue)) {
            // setSelections((prevVal) => ({
            //     indexes: prevVal.indexes.concat([selectionIndex]),
            //     values: prevVal.values.concat([selectionValue]),
            // }));

            setSelections((prevVal) => ({
                indexes: [...prevVal.indexes, selectionIndex],
                values: [...prevVal.values, selectionValue],
            }));

            // pass values to onSelect function if given
            if (onSelect) {
                onSelect({
                    indexes: [...selections.indexes, selectionIndex],
                    values: [...selections.values, selectionValue],
                });
            }
        } else {
            // const indexToRemove = selections.values.indexOf(selectionValue);
            // setSelections((prevVal) => ({
            //     indexes: prevVal.indexes
            //         .slice(0, indexToRemove)
            //         .concat(prevVal.indexes.slice(indexToRemove + 1)),
            //     values: prevVal.values
            //         .slice(0, indexToRemove)
            //         .concat(prevVal.values.slice(indexToRemove + 1)),
            // }));

            setSelections((prevVal) => ({
                indexes: prevVal.indexes.filter(
                    (index) => index !== selectionIndex
                ),
                values: prevVal.values.filter(
                    (value) => value !== selectionValue
                ),
            }));

            // pass values to onSelect function if given
            if (onSelect) {
                onSelect({
                    indexes: selections.indexes.filter(
                        (index) => index !== selectionIndex
                    ),
                    values: selections.values.filter(
                        (value) => value !== selectionValue
                    ),
                });
            }
        }

        // pass event object to onChange function if given
        if (onChange) {
            onChange(e);
        }
    };

    return (
        <div
            placeholder={placeholder}
            className={classNames(styles.primaryDropdownContainer, className)}
        >
            <div
                className={classNames(
                    styles.selection,
                    selections.values.length !== 0 && styles.colorGray900
                )}
                ref={selectionRef}
                onClick={handleDropdown}
            >
                <p>
                    {selections.values.length !== 0
                        ? selections.values
                              .map(
                                  (value) =>
                                      value.charAt(0).toUpperCase() +
                                      value.slice(1).toLowerCase()
                              )
                              .join(', ')
                        : placeholder}
                </p>
                {dropdownOpened ? <ChevronUp /> : <ChevronDown />}
            </div>
            <div
                className={classNames(
                    styles.selectionMenu,
                    !dropdownOpened && styles.closed
                )}
            >
                {list.map((listItem, i) => (
                    <div key={i}>
                        <div
                            className={classNames(
                                styles.selectionItem,
                                selections.values.includes(
                                    listItem.toLowerCase()
                                ) && styles.selected
                            )}
                            onClick={handleSelection}
                        >
                            <p>{listItem}</p>
                            <div className={styles.checkBox}>
                                <Check className={styles.check} />
                            </div>
                            <div className={styles.target} data-index={i} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
