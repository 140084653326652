import React from 'react';
import ChevronBIcon from '../../assets/icons/chevron-black.svg';
import { PageLink } from '../../common';
import styles from './PageTitle.module.scss';

export interface PageTitleProps {
    title?: string;
    back_to?: string;
}

export const PageTitle: React.FC<PageTitleProps> = ({ title, back_to }) => {
    return (
        <div className={styles.pagetitle}>
            {back_to && (
                <PageLink to={back_to}>
                    <img
                        className={styles.backbutton}
                        src={ChevronBIcon}
                        alt="go-back"
                    ></img>
                </PageLink>
            )}
            <p className={styles.title}>{title?.toUpperCase()}</p>
        </div>
    );
};
