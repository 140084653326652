import React from 'react';

import { X } from '../../../../../assets/icons';

import styles from './QuestionModal.module.scss';

interface ModalProps {
    chk: (value: boolean) => void;
    img: string | any; // Path to your PDF file
}

export const QuestionModal: React.FC<ModalProps> = ({ chk, img }) => {
    return (
        <div className={styles.modal}>
            <div
                className={styles.modalBackground}
                onClick={() => chk(false)}
            />
            <div className={styles.modalContainer}>
                <X className={styles.x} onClick={() => chk(false)} />

                <div
                    className={styles.questionContainer}
                    style={{
                        overflow: 'auto',
                    }}
                >
                    <img
                        src={img}
                        alt=""
                        srcSet=""
                        className={styles.question}
                    />
                </div>
            </div>
        </div>
    );
};
