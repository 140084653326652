import React, { useEffect, useState } from 'react';

import styles from './Dashboard.module.scss';

import { useDispatch } from 'react-redux';
import { Navbar } from '../../components';
import {
    Header,
    InfoGrid,
    LoadingModal,
    OptionsGrid,
    PhysicsBox,
    SignUpModals,
    Statement,
} from '../../components/Dashboard';
import { useOnboardMutation } from '../../features/auth/authApiSlice';
import { setCredentials } from '../../features/auth/userSlice';
import {
    useGetCurrentUser,
    useIsUserOnboarded,
} from '../../features/auth/hooks';
import { ApiError } from '../../types';

export const Dashboard: React.FC = () => {
    const dispatch = useDispatch();
    const [onboard] = useOnboardMutation();
    const isUserOnboarded = useIsUserOnboarded();
    const currentUser = useGetCurrentUser();

    const [isOnboarded, setIsOnboarded] = useState<boolean>(
        isUserOnboarded ? true : false
    );
    const [wasOnboarded, setWasOnboarded] = useState<boolean>(isOnboarded);
    const [loadDashboard, setLoadDashboard] = useState<boolean>(false);

    const [isPrivate, setIsPrivate] = useState<boolean>(false);
    const [school, setSchool] = useState<string>('');
    const [grade, setGrade] = useState<string>('');
    const [curriculum, setCurriculum] = useState<string>('');

    const getters: any = {
        isPrivate: isPrivate,
        school: school,
        grade: grade,
        curriculum: curriculum,
    };
    const setters: any = {
        setIsPrivate: setIsPrivate,
        setSchool: setSchool,
        setGrade: setGrade,
        setCurriculum: setCurriculum,
    };

    const userDetails: {
        isPrivate: boolean;
        school: string;
        grade: string;
        curriculum: string;
    } = {
        isPrivate: isPrivate,
        school: school,
        grade: grade,
        curriculum: curriculum,
    };

    const handleOnBoardUser = async () => {
        try {
            const user = await onboard({
                email: currentUser?.email,
                school: userDetails.school,
                isPrivate: userDetails.isPrivate,
                grade: userDetails.grade,
                curriculum: userDetails.curriculum,
            }).unwrap();
            dispatch(setCredentials({ user }));
            setIsOnboarded(true);
        } catch (err) {
            const error = err as ApiError;
            console.log(error.status);
        }
    };

    useEffect(() => {
        if (isUserOnboarded) {
            setIsOnboarded(true);
        }

        setWasOnboarded((prevVal: boolean) => prevVal);
    }, [isUserOnboarded]);

    return (
        <div className={styles.dashboardContainer}>
            {wasOnboarded ? (
                <LoadingModal
                    header={'Dressing up'}
                    text={'Just a second while we get ready...'}
                    setPageLoader={setLoadDashboard}
                />
            ) : (
                <SignUpModals
                    {...getters}
                    {...setters}
                    setPageLoader={setLoadDashboard}
                    onSubmit={handleOnBoardUser}
                />
            )}
            {loadDashboard && (
                <div className={styles.dashboard}>
                    <Navbar loggedIn={true} />
                    <Header />
                    <OptionsGrid />
                    <Statement />
                    <PhysicsBox />
                    <InfoGrid />
                </div>
            )}
        </div>
    );
};
