export const currentMockTopics = [
    'Physical quantities and units',
    'Kinematics',
    'Dynamics',
    'Forces, density and pressure',
    'Work, energy and power',
    'Deformation of solids',
    'Waves',
    'Superposition',
    'Electricity',
    'D.C. circuits',
    'Particle physics',
    'Motion in a circle',
    'Gravitational fields',
    'Temperature',
    'Ideal gases',
    'Thermodynamics',
    'Oscillations',
    'Electric fields',
    'Capacitance',
    'Magnetic fields',
    'Alternating currents',
    'Quantum physics',
    'Nuclear physics',
    'Medical physics',
    'Astronomy and cosmology',
];

export const currentMockCurriculums = ['Cambridge'];

export const currentMockSubjects = ['Physics'];

export const currentMockLevels = ['O-levels', 'A-levels'];

export const currentMockDifficulties = ['Easy', 'Medium', 'Hard'];

export const currentMockPapers = ['Paper 1', 'Paper 2', 'Paper 4', 'Paper 5'];
