import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { useLogoutMutation } from '../../features/auth/authApiSlice';
import {
    useGetCurrentUser,
    useGetUserCourses,
} from '../../features/auth/hooks';
import { logOut } from '../../features/auth/userSlice';
import { ApiError } from '../../types';

import { Button, Typography } from '../../common';
import { Gear, HexaBetaLogo, Home, LogOut } from '../../assets';

import styles from './Navbar.module.scss';

export interface Props {
    loggedIn: boolean;
}

export const Navbar: React.FC<Props> = ({ loggedIn }: Props) => {
    const [userMenu, setUserMenu] = useState<boolean>(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useGetCurrentUser();
    const userCourses = useGetUserCourses();

    const [logout, { isLoading }] = useLogoutMutation();

    const toggleUserMenu = () => {
        setUserMenu((prevVal: boolean) => !prevVal);
    };

    const handleLogout = async () => {
        try {
            await logout({}).unwrap();
            dispatch(logOut());
            navigate('/');
        } catch (err) {
            const error = err as ApiError;
            console.log(error.status);
        }
    };

    return (
        <div
            style={{ justifyContent: loggedIn ? 'space-between' : 'center' }}
            className={styles.navbarContainer}
        >
            <Link to="/">
                <HexaBetaLogo className={styles.hexaLogo} />
            </Link>
            {loggedIn && (
                <div className={styles.actionButtons}>
                    {!userCourses.length && (
                        <Link to="/premium">
                            <Button
                                styleType="outlineYellow"
                                className={styles.premiumBtn}
                            >
                                <Typography variant="text-xs-semibold">
                                    Go Premium
                                </Typography>
                            </Button>
                        </Link>
                    )}
                    <div className={styles.userBtn} onClick={toggleUserMenu}>
                        <Typography variant="text-sm-medium">
                            {`${user?.firstName
                                .charAt(0)
                                .toUpperCase()} ${user?.lastName
                                .charAt(0)
                                .toUpperCase()}`}
                        </Typography>
                    </div>
                    {userMenu && (
                        <div className={styles.userMenuContainer}>
                            <div
                                className={styles.invisibleBg}
                                onClick={toggleUserMenu}
                            />
                            <div className={styles.userMenu}>
                                <div className={styles.credentials}>
                                    <Typography variant="text-sm-semibold">
                                        {`${user?.firstName} ${user?.lastName}`}
                                    </Typography>
                                    <Typography variant="text-sm-regular">
                                        {`${user?.email}`}
                                    </Typography>
                                </div>
                                <Link
                                    to={'/dashboard'}
                                    className={styles.dashboardBtn}
                                >
                                    <Home className={styles.icon} />
                                    <Typography variant="text-sm-medium">
                                        Dashboard
                                    </Typography>
                                </Link>
                                <Link
                                    to={'/settings'}
                                    className={styles.dashboardBtn}
                                >
                                    <Gear className={styles.icon} />
                                    <Typography variant="text-sm-medium">
                                        Settings
                                    </Typography>
                                </Link>
                                <button
                                    onClick={handleLogout}
                                    className={styles.logOutBtn}
                                    disabled={isLoading}
                                >
                                    <LogOut className={styles.icon} />
                                    <Typography variant="text-sm-medium">
                                        Log out
                                    </Typography>
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
