import React from 'react';
import { Typography } from '../../../common';
import styles from './Paper.module.scss';

import Metrics from '../../../assets/home/Metrics.png';
import Question from '../../../assets/home/Question.png';
import Sparkles from '../../../assets/home/Sparkle.png';
import Split from '../../../assets/home/Split.png';
import Timer from '../../../assets/home/Timer.png';

export const Paper: React.FC = () => {
    return (
        <div className={styles.paperContainer}>
            <div className={styles.rowContainer1}>
                <div
                    // data-aos="fade-right"
                    className={styles.papersContainer}
                >
                    <div className={styles.papersText}>
                        <Typography variant="title-md-semibold">
                            Past paper database
                        </Typography>
                        <Typography variant="text-xl-regular">
                            No need to open another tab for looking up past
                            papers. It’s a one-stop drop.
                        </Typography>
                    </div>
                    <img className={styles.papersImage} src={Question} alt="" />
                </div>
                <div
                    // data-aos="fade-left"
                    className={styles.timeContainer}
                >
                    <img className={styles.timeImage} src={Timer} alt="" />
                    <div className={styles.timeText}>
                        <div>
                            <Typography
                                variant="title-md-semibold"
                                color="grey"
                            >
                                Tick tock
                            </Typography>
                            <Typography
                                variant="title-md-semibold"
                                color="white"
                            >
                                Time is money.
                            </Typography>
                        </div>
                        <Typography variant="text-xl-regular">
                            Utilize your time through pomodoro routines, or make
                            up a timer of your own with the personalized study
                            timer.
                        </Typography>
                    </div>
                </div>
            </div>
            <div
                // data-aos="fade-up"
                className={styles.rowContainer2}
            >
                <div className={styles.metricsContainer}>
                    <div className={styles.metricsText}>
                        <Typography variant="title-md-semibold">
                            Sorting Metrics
                        </Typography>
                        <Typography variant="text-xl-regular">
                            Easily evaluate and decide which past paper to
                            practice based on difficulty and usefulness ratings.
                        </Typography>
                    </div>
                    <img className={styles.metricsImage} src={Metrics} alt="" />
                </div>
                <div className={styles.splitviewContainer}>
                    <div className={styles.splitviewText}>
                        <Typography variant="title-md-semibold">
                            Split view
                        </Typography>
                        <Typography variant="text-xl-regular">
                            View questions papers and mark schemes together,
                            without the need to toggle between tabs.
                        </Typography>
                    </div>
                    <img className={styles.splitviewImage} src={Split} alt="" />
                </div>
            </div>
            <div
                // data-aos="zoom-in-up"
                className={styles.personalizedContainer}
            >
                <Typography variant="text-md-regular">Coming up</Typography>
                <div className={styles.personalizedHeader}>
                    <img
                        className={styles.sparkles}
                        src={Sparkles}
                        alt="sparkles"
                    />
                    <Typography variant="title-xl-semibold">
                        Personalized Mock Exams
                    </Typography>
                </div>
                <Typography variant="text-xl-regular">
                    Get personalized mock papers - that cater to you. And you
                    only. Figure out your weaknesses and hone on those topics,
                    without having to tirelessly search for related questions
                    online.
                </Typography>
            </div>
        </div>
    );
};
