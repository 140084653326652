import React from 'react';

import { Typography } from '../../../common';

import styles from './SettingsHeader.module.scss';

export const SettingsHeader: React.FC = () => {
    return (
        <div className={styles.headerContainer}>
            <Typography
                variant="title-md-semibold"
                localClassNames={styles.headerText}
            >
                Settings
            </Typography>
        </div>
    );
};
