import classNames from 'classnames';
import React, { useRef, useState } from 'react';

import { Check, ChevronDown, ChevronUp } from '../../assets';
import { Typography } from '../Typography';

import styles from './Dropdown.module.scss';

export type DropdownSelection = {
    index: number;
    value: string;
};

export interface DropdownProps {
    list: string[];
    placeholder?: string;
    // required: boolean;
    disabled?: boolean;
    className?: string;
    containerClassname?: string;
    moreComingSoon?: boolean;
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
    onSelect?: (selection: DropdownSelection) => void;
    onChange?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const Dropdown: React.FC<DropdownProps> = ({
    list,
    placeholder = '-',
    className,
    moreComingSoon,
    onSelect,
    onChange,
}) => {
    const dropdownMenuItemCount: number =
        list.length + (moreComingSoon ? 1 : 0);
    const dropdownMenuHeight: number =
        dropdownMenuItemCount <= 5 ? dropdownMenuItemCount : 5; // 5 items tall

    const selectionRef = useRef(null);

    const [dropdownOpened, setDropdownOpened] = useState(false);
    const [selection, setSelection] = useState(placeholder);

    const handleDropdown = () => {
        setDropdownOpened((prevVal) => !prevVal);
    };
    const handleSelection = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        // set value locally
        const value = (
            (e.target as HTMLDivElement).parentNode
                ?.childNodes[0] as HTMLDivElement
        ).innerText;
        setSelection(value);

        //
        if (onSelect) {
            onSelect({
                index: Number((e.target as HTMLDivElement).dataset.index),
                value: (
                    (e.target as HTMLDivElement).parentNode
                        ?.childNodes[0] as HTMLDivElement
                ).innerText.toLowerCase(),
            });
        }

        // call the passed onChange if any
        if (onChange) {
            onChange(e);
        }

        // close dropdown
        handleDropdown();
    };

    return (
        <div
            placeholder={placeholder}
            className={classNames(styles.primaryDropdownContainer, className)}
        >
            <div
                className={classNames(
                    styles.selection,
                    selection != placeholder && styles.colorGray900
                )}
                ref={selectionRef}
                onClick={handleDropdown}
            >
                <p>{selection}</p>
                {dropdownOpened ? <ChevronUp /> : <ChevronDown />}
            </div>
            <div
                style={{
                    overflowX: 'hidden',
                    overflowY:
                        dropdownOpened && list.length > dropdownMenuHeight
                            ? 'scroll'
                            : 'hidden',
                    position: 'absolute',
                    height: dropdownOpened
                        ? `calc(${dropdownMenuHeight} * 100% + ${dropdownMenuHeight} * 7px)`
                        : 0,
                }}
                className={classNames(
                    styles.selectionMenu,
                    !dropdownOpened && styles.closed
                )}
            >
                {list.map((listItem, i) => (
                    <div key={i}>
                        <div
                            className={classNames(
                                styles.selectionItem,
                                selection === listItem && styles.selected
                            )}
                            onClick={handleSelection}
                        >
                            <p>{listItem}</p>
                            <Check />
                            <div className={styles.target} data-index={i} />
                        </div>
                    </div>
                ))}
                {moreComingSoon && (
                    <div>
                        <div className={styles.moreComingSoon}>
                            <Typography variant="text-md-regular">
                                More coming soon
                            </Typography>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
