// import Cookies from 'js-cookie';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { selectCurrentUser } from '../auth/userSlice';

export const SignedOutRedirect: React.FC = () => {
    const user = useSelector(selectCurrentUser);
    const location = useLocation();
    // const token = Cookies.get('jwt');
    // console.log(user?.accessToken === token);

    // return token && user?.accessToken === token ? (
    return user ? (
        <Navigate to="/dashboard" state={{ from: location }} replace />
    ) : (
        <Outlet />
    );
};
