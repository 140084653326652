import { apiSlice } from '../../app/apiSlice';

export const mockApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        request: builder.mutation({
            query: (mockData) => ({
                url: '/mock/request',
                method: 'POST',
                body: { ...mockData },
            }),
        }),
    }),
});

export const { useRequestMutation } = mockApiSlice;
