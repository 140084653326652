import React from 'react';

import { Typography } from '../../../common';

import styles from './Header.module.scss';

export const Header: React.FC = () => {
    return (
        <div className={styles.headerContainer}>
            <p className={styles.gradientTextBg}>Premium</p>
            <Typography variant="title-2xl-semibold">
                Learning made delightful.
            </Typography>
            <Typography variant="title-xs-medium">
                Gain frictionless access to an incredible range of resources and
                tools. Whenever. Wherever.*
            </Typography>
        </div>
    );
};
