import React from 'react';
import { usePageTracking } from './';

type TrackableRouteProps = {
    children: React.ReactNode;
};

export const TrackedRoute: React.FC<TrackableRouteProps> = ({ children }) => {
    usePageTracking();

    return <>{children}</>;
};
