import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useGetUserCourses } from '../../../features/auth/hooks';

import { ChevronWIcon, LockWIcon, PlayIcon } from '../../../assets';
import { Button, PageLink, Typography } from '../../../common';
import { Lesson } from '../../../data';

import styles from './Dropdown.module.scss';

export interface LessonDropdownProps {
    lesson: Lesson;
}

export const LessonDropdown: React.FC<LessonDropdownProps> = ({ lesson }) => {
    const params = useParams();

    const [isOpen, setIsOpen] = useState(false);

    const userCourses = useGetUserCourses();

    const checkisPremium = () => {
        for (const c of userCourses) {
            if (c == params.course) {
                return true;
            }
        }
        return false;
    };
    return (
        <div className={styles.lessonInfo}>
            <div className={styles.content} onClick={() => setIsOpen(!isOpen)}>
                <div className={styles.description}>
                    <Typography variant="title-xs-medium" color="white">
                        {lesson.description}
                    </Typography>
                    {lesson.free_available && (
                        <div className={styles.freeLessonTag}>
                            <Typography variant="text-xs-regular" color="white">
                                Free lesson available!
                            </Typography>
                        </div>
                    )}
                </div>
                <img
                    className={classNames(
                        styles.dropdownIcon,
                        isOpen && styles.iconUp
                    )}
                    src={ChevronWIcon}
                    alt="chevron"
                ></img>
            </div>
            <div
                className={classNames(
                    styles.dropdowns,
                    isOpen && styles.dropdowns_open
                )}
            >
                {lesson?.sublessons.map((sublesson, idx) => (
                    <div key={idx} className={styles.dropdown}>
                        <Typography variant="text-xl-medium" color="grey-900">
                            {`${idx + 1}. ${sublesson.title}`}
                        </Typography>
                        <PageLink
                            to={
                                sublesson?.free || checkisPremium()
                                    ? `${lesson.id}/sub-lessons/${sublesson.id}`
                                    : '/premium'
                            }
                        >
                            <Button
                                styleType={
                                    sublesson.free ? 'success' : 'primary'
                                }
                                className={styles.button}
                            >
                                {sublesson?.free || checkisPremium() ? (
                                    <PlayIcon />
                                ) : (
                                    <LockWIcon />
                                )}
                                <Typography
                                    variant="text-md-semibold"
                                    color="white"
                                >
                                    {sublesson.free ? 'Free' : 'Start'}
                                </Typography>
                            </Button>
                        </PageLink>
                    </div>
                ))}
                {lesson.related_qlink && (
                    <div className={styles.relatedQuestions}>
                        {/* Check for any free sublesson */}
                        {checkisPremium() ||
                        lesson.sublessons.some(
                            (sublesson) => sublesson.free
                        ) ? (
                            <PageLink
                                className={classNames(
                                    styles.relatedLink,
                                    styles.recapLink
                                )}
                                to={`/learn/physics/chapters/${params.chapter}/lessons/${lesson.id}/recap`}
                            >
                                <Typography variant="text-md-medium">
                                    Recap this lesson
                                </Typography>
                            </PageLink>
                        ) : (
                            <PageLink
                                className={classNames(
                                    styles.relatedLink,
                                    styles.recapLink
                                )}
                                to={'/premium'}
                            >
                                <LockWIcon />
                                <Typography variant="text-md-medium">
                                    Recap this lesson
                                </Typography>
                            </PageLink>
                        )}
                        {lesson.sublessons.some(
                            (sublesson) => sublesson.free
                        ) ? (
                            <PageLink
                                className={classNames(
                                    styles.relatedLink,
                                    styles.questionLink
                                )}
                                to={`${lesson.id}/questions`}
                            >
                                <Typography variant="text-md-medium">
                                    Solve related questions
                                </Typography>
                            </PageLink>
                        ) : (
                            <PageLink
                                className={classNames(
                                    styles.relatedLink,
                                    styles.questionLink
                                )}
                                to={'/premium'}
                            >
                                <LockWIcon />
                                <Typography variant="text-md-medium">
                                    Solve related questions
                                </Typography>
                            </PageLink>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
