import React from 'react';

import styles from './Header.module.scss';
import { useGetCurrentUser } from '../../../features/auth/hooks';

import { Typography } from '../../../common';

export const Header: React.FC = () => {
    const user = useGetCurrentUser();
    const firstname = user?.firstName ? user.firstName : 'friend';
    return (
        <div className={styles.headerContainer}>
            <p>Dashboard</p>
            <Typography variant="title-xl-semibold">
                Good to see you,{' '}
                {`${firstname.charAt(0).toUpperCase()}${firstname.substring(
                    1
                )}`}
                !
            </Typography>
        </div>
    );
};
