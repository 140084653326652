import { useSelector } from 'react-redux';
import { selectCurrentUser } from './userSlice';
import { UserData } from './types';

export const useIsUserOnboarded = (): boolean => {
    const user = useSelector(selectCurrentUser);
    if (!user) {
        return false;
    }
    const { school, grade, isPrivate, curriculum } = user;
    if ((school && isPrivate) || grade || curriculum) {
        return true;
    }
    return false;
};

export const useGetCurrentUser = (): UserData | null => {
    const user = useSelector(selectCurrentUser);
    return user;
};

export const useGetUserCourses = (): string[] | [] => {
    const user = useSelector(selectCurrentUser);
    if (user?.courses) {
        return user.courses;
    } else {
        return [];
    }
};
