import { apiSlice } from '../../app/apiSlice';

export const userApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        update: builder.mutation({
            query: ({ updateUserData, userId }) => ({
                url: `/users/${userId}`,
                method: 'PATCH',
                body: { ...updateUserData },
            }),
        }),
    }),
});

export const { useUpdateMutation } = userApiSlice;
