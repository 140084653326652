import React from 'react';

import { ReactComponent as X } from '../../../assets/icons/x.svg';
import { Button, PageLink, Typography } from '../../../common';
import styles from './ThankYouModal.module.scss';

export interface Props {
    closeFn: any;
}

export const ThankYouModal: React.FC<Props> = ({ closeFn }: Props) => {
    return (
        <div className={styles.thankYouModal}>
            <div className={styles.thankYouModalBg} onClick={closeFn} />
            <div className={styles.thankYouModalContainer}>
                <div className={styles.thankYouModalContent}>
                    <Typography variant="title-md-semibold">
                        Thank you for choosing Hexa Premium. We’ll get back to
                        you very soon.
                    </Typography>
                    <div className={styles.actionButtons}>
                        <PageLink to="/dashboard">
                            <Button
                                styleType="primary"
                                className={styles.doneBtn}
                            >
                                <Typography variant="text-md-semibold">
                                    Go back to Dashboard
                                </Typography>
                            </Button>
                        </PageLink>
                    </div>
                    <X className={styles.closeBtn} onClick={closeFn} />
                </div>
            </div>
        </div>
    );
};
