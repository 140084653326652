import React from 'react';

import { Outlet } from 'react-router-dom';
import { Footer } from '../Footer';
import { Scroll } from '../../components';

export const Layout: React.FC = () => {
    return (
        <>
            <Scroll />
            <Outlet />
            <Footer />
        </>
    );
};
