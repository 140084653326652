import React, { useEffect, useRef } from 'react';
import Container from '../../../assets/home/Container.png';
import Tabs from '../../../assets/home/Tabs.png';
import lessTabs from '../../../assets/home/lessTabs.png';
import moving from '../../../assets/home/moving.png';
import { Typography } from '../../../common';
import styles from './Moving.module.scss';

export const Moving: React.FC = () => {
    const tabsImg = useRef<HTMLImageElement>(null);
    const lessTabsImg = useRef<HTMLImageElement>(null);

    useEffect(() => {
        document.addEventListener('aos:in:super-duper', () => {
            if (tabsImg.current) {
                tabsImg.current.style.opacity = '0';
            }
            if (lessTabsImg.current) {
                lessTabsImg.current.style.transform = 'translateX(0%)';
                lessTabsImg.current.style.opacity = '1';
            }
        });
    }, [tabsImg, lessTabsImg]);

    return (
        <div className={styles.componentRootContainer}>
            <div className={styles.movingContainer}>
                <div
                    // data-aos="fade-up"
                    className={styles.heading}
                >
                    <Typography variant="title-2xl-semibold" alignment="center">
                        <span className={styles.heading1}>Moving async.</span>{' '}
                        <br />{' '}
                        <span className={styles.heading2}>
                            At your own pace.
                        </span>
                    </Typography>
                </div>
                <div className={styles.movingBoxContainer}>
                    <div
                        // data-aos="fade-right"
                        className={styles.movingBox1}
                    >
                        <div className={styles.movingBoxText}>
                            <Typography variant="title-md-semibold">
                                Complimentary notes with each video.
                            </Typography>
                            <Typography variant="title-xs-medium">
                                Details and summaries - enjoy both with the
                                notes provided.
                            </Typography>
                        </div>
                        <img
                            className={styles.movingBoxImage}
                            src={moving}
                            alt=""
                        />
                    </div>
                    <div
                        // data-aos="fade-left"
                        className={styles.movingBox2}
                    >
                        <img
                            className={styles.movingBoxImage}
                            src={Container}
                            alt=""
                        />
                        <div className={styles.movingBoxText}>
                            <Typography variant="title-md-semibold">
                                MCQs for each lesson.
                            </Typography>
                            <Typography variant="title-xs-medium">
                                Interactiveness - personified.
                            </Typography>
                        </div>
                    </div>
                </div>
                <div
                    // data-aos="fade-up"
                    className={styles.biteBox}
                >
                    <Typography variant="title-lg-semibold" color="white">
                        Bite-sized recaps to finish it off.
                    </Typography>
                    <Typography variant="title-xs-medium" color="white">
                        Quickly revise all the vital concepts that you need to
                        know for each lesson.{' '}
                    </Typography>
                </div>
                <div className={styles.goalContainer}>
                    <div
                        // data-aos="fade-up"
                        className={styles.goalText}
                    >
                        <Typography
                            variant="text-xl-regular"
                            alignment="center"
                        >
                            The goal, always, is to try and ensure that you have
                            access to as many resources as possible, without
                            having to open another tab, or another website.
                            Which swiftly takes us to the Practice section.
                        </Typography>
                    </div>
                    <div className={styles.goalAnimation}>
                        <img
                            className={styles.tabsImage}
                            src={Tabs}
                            alt=""
                            data-aos="..."
                            data-aos-id="super-duper"
                            ref={tabsImg}
                        />
                        <img
                            className={styles.lessTabsImage}
                            src={lessTabs}
                            alt=""
                            data-aos="..."
                            data-aos-id="super-duper"
                            ref={lessTabsImg}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
