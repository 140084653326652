import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setUpdatedUserDetails } from '../../../features/auth/userSlice';
import { useUpdateMutation } from '../../../features/auth/userApiSlice';
import { Button, Input, Typography } from '../../../common';
import { useGetCurrentUser } from '../../../features/auth/hooks';
import { UserAccountDetails } from '../../../types';
import { ApiError } from '../../../types';

import styles from './UserDetailsTab.module.scss';

export const UserDetailsTab: React.FC = () => {
    const dispatch = useDispatch();
    const [updateUser, { isLoading }] = useUpdateMutation();
    const firstNameRef = useRef<HTMLFormElement>(null);
    const lastNameRef = useRef<HTMLFormElement>(null);
    const emailRef = useRef<HTMLFormElement>(null);

    const currentUser = useGetCurrentUser();

    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [error, setError] = useState<string>('');

    const handleFormClear = () => {
        firstNameRef.current?.reset();
        lastNameRef.current?.reset();
        emailRef.current?.reset();

        setFirstName('');
        setLastName('');
        setEmail('');
        setTimeout(() => setError(''), 5000); // the error disappears after 5s
    };

    const handleUpdateUserDetails = async () => {
        setError('');
        try {
            const updateUserPayload: UserAccountDetails = {
                firstName: firstName,
                lastName: lastName,
                email: email,
            };
            const updatedDetails = await updateUser({
                updateUserData: updateUserPayload,
                userId: currentUser?.id,
            }).unwrap();
            dispatch(setUpdatedUserDetails({ updatedDetails }));
        } catch (err) {
            const apiErr = err as ApiError;
            if (apiErr.status == 409) {
                setError(
                    'User already exists with this email. Please use a different one.'
                );
            } else {
                setError(
                    'Couldn`t update user profile, Please try again later.'
                );
            }
        }
    };

    const handleFormSubmit = () => {
        if (
            (firstName && firstName != currentUser?.firstName) ||
            (lastName && lastName != currentUser?.lastName) ||
            (email && email != currentUser?.email)
        ) {
            handleUpdateUserDetails();
            handleFormClear();
        } else {
            setError(
                'Please provide new and valid data to update your account.'
            );
        }
    };

    return (
        <div className={styles.userDetailsTabContainer}>
            <div className={styles.userDetails}>
                <div className={styles.user}>
                    {/* <div className={styles.userPhoto}></div> */}
                    <div className={styles.userText}>
                        <Typography
                            variant="title-sm-semibold"
                            localClassNames={styles.name}
                        >
                            {`${currentUser?.firstName} ${currentUser?.lastName}`}
                        </Typography>
                        <Typography
                            variant="text-md-regular"
                            localClassNames={styles.instructions}
                        >
                            Update your photo and personal details.
                        </Typography>
                    </div>
                </div>
                <div className={styles.actionBtns}>
                    <Button
                        styleType="outlineBlack"
                        onClick={handleFormClear}
                        disabled={isLoading}
                    >
                        <Typography
                            variant="text-sm-semibold"
                            localClassNames={styles.instructions}
                        >
                            Cancel
                        </Typography>
                    </Button>
                    <Button
                        styleType="primary"
                        onClick={handleFormSubmit}
                        disabled={isLoading}
                    >
                        <Typography
                            variant="text-sm-semibold"
                            localClassNames={styles.instructions}
                        >
                            Save
                        </Typography>
                    </Button>
                </div>
            </div>
            <form className={styles.userDetailsForm}>
                <div className={styles.userDetailForm}>
                    <div className={styles.formField}>
                        <Typography
                            variant="text-sm-medium"
                            localClassNames={styles.label}
                        >
                            First Name
                        </Typography>
                        <Input
                            inputType="text"
                            placeholder={currentUser?.firstName}
                            required={false}
                            inputClassname={styles.input}
                            onChange={(value: string) => setFirstName(value)}
                            ref={firstNameRef}
                        />
                    </div>
                    <div className={styles.formField}>
                        <Typography
                            variant="text-sm-medium"
                            localClassNames={styles.label}
                        >
                            Last Name
                        </Typography>
                        <Input
                            inputType="text"
                            placeholder={currentUser?.lastName}
                            required={false}
                            inputClassname={styles.input}
                            onChange={(value: string) => setLastName(value)}
                            ref={lastNameRef}
                        />
                    </div>
                </div>
                {/* <div className={styles.userDetailForm}></div> */}
                <div className={styles.userDetailForm}>
                    <div className={styles.formField}>
                        <Typography
                            variant="text-sm-medium"
                            localClassNames={styles.label}
                        >
                            Email Address
                        </Typography>
                        <Input
                            inputType="email"
                            placeholder={currentUser?.email}
                            required={false}
                            inputClassname={styles.input}
                            onChange={(value: string) => setEmail(value)}
                            ref={emailRef}
                        />
                    </div>
                </div>
            </form>
            {error && (
                <Typography variant="text-md-regular" color="error-400">
                    {error}
                </Typography>
            )}
        </div>
    );
};
