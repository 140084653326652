import { ContentItem } from './content';
import * as courseDataJSON from './courses.json';
import * as paperDataJSON from './papers.json';
import * as bitesizedRecapsDataJSON from './bitesizedRecaps.json';
import * as topicalQuizzesDataJSON from './topical_quizzes.json';
import * as competitiveQuizzesDataJSON from './competitive_quizzes.json';

export interface Sublesson {
    id: number;
    title: string;
    free?: boolean;
    content?: ContentItem[];
}

export interface Lesson {
    id: number;
    description: string;
    free_available: boolean;
    sublessons: Sublesson[];
    related_qlink?: string;
}
export interface Chapter {
    id: number;
    title: string;
    description: string;
    lessons: Lesson[];
}

export interface Instructor {
    name: string;
    degree: string;
    institution: string;
}

export interface Course {
    id: string;
    curriculum: string;
    heading: string;
    instructor: Instructor;
    trailer: string;
    chapters: Chapter[];
}

interface Courses {
    [key: string]: Course;
}

export interface RelatedQInfo {
    curriculum: string;
    title: string;
    relatedQlink?: string;
}

export interface Paper {
    variant: number;
    year: number;
    session: string;
    qplink: string;
    mslink: string;
    usefulness: number;
    difficulty: number;
}

export interface Subject {
    subjectcode: number;
    papers: Paper[];
}
export interface Level {
    [key: string]: Subject;
}
export interface Curriculum {
    [key: string]: Level;
}

export interface Papers {
    [key: string]: Curriculum;
}

export interface Slide {
    imgSrc?: string;
    text: string;
}

export interface Recaps {
    [key: string]: Slide[];
}

export interface TopicalQuiz {
    img: string;
    answer: string;
}

export interface TopicalQuizzes {
    [key: string]: TopicalQuiz[];
}

export interface CompetitiveQuiz {
    img: string;
    answer: string;
    curriculum: string;
    level: string;
    subject: string;
    topic: string;
}

export const courses: Courses = courseDataJSON as Courses;
export const papers: Papers = paperDataJSON as Papers;
export const bitesizedRecaps: Recaps = bitesizedRecapsDataJSON as Recaps;
export const topicalQuizzes: TopicalQuizzes =
    topicalQuizzesDataJSON as TopicalQuizzes;
export const competitiveQuizzes: CompetitiveQuiz[] =
    competitiveQuizzesDataJSON as CompetitiveQuiz[];
