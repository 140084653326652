import React, { useRef, useState } from 'react';

import { Button, Input, Typography } from '../../../common';
import { ApiError } from '../../../types';
import { useGetCurrentUser } from '../../../features/auth/hooks';
import { useChangePasswordMutation } from '../../../features/auth/authApiSlice';
import { ChangePasswordDetails } from '../../../features/auth/types';

import styles from './UserPasswordTab.module.scss';

export const UserPasswordTab: React.FC = () => {
    const [changePassword, { isLoading }] = useChangePasswordMutation();
    const currentUser = useGetCurrentUser();
    const currentPasswordRef = useRef<HTMLFormElement>(null);
    const newPasswordRef = useRef<HTMLFormElement>(null);
    const confirmedPasswordRef = useRef<HTMLFormElement>(null);

    const [currentPassword, setcurrentPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmedPassword, setConfirmedPassword] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');

    const handleChangePassword = async () => {
        try {
            const changePasswordPayload: ChangePasswordDetails = {
                email: currentUser?.email,
                currentPassword: currentPassword,
                newPassword: newPassword,
            };
            await changePassword(changePasswordPayload).unwrap();
        } catch (err) {
            const apiErr = err as ApiError;
            if (apiErr.status == 400) {
                setErrorMessage(
                    `Password change failed - ${apiErr.data?.message}`
                );
            } else {
                setErrorMessage(
                    'Password change failed. Please try again later.'
                );
            }
        }
    };

    const handleFormClear = () => {
        currentPasswordRef.current?.reset();
        newPasswordRef.current?.reset();
        confirmedPasswordRef.current?.reset();

        setcurrentPassword('');
        setNewPassword('');
        setConfirmedPassword('');
        setTimeout(() => setErrorMessage(''), 5000); // the error disappears after 5s
    };

    const handleFormSubmit = () => {
        setErrorMessage('');
        if (
            currentPassword !== '' &&
            newPassword !== '' &&
            confirmedPassword !== ''
        ) {
            if (newPassword !== confirmedPassword) {
                setErrorMessage('New password doesn`t match.');
            } else if (currentPassword === newPassword) {
                setErrorMessage(
                    'New password cannot be same as current password.'
                );
            } else {
                handleChangePassword();
                handleFormClear();
            }
        } else {
            setErrorMessage(
                'Please fill out all fields above to process the password change!'
            );
        }
    };

    return (
        <div className={styles.userPasswordTabContainer}>
            <div className={styles.header}>
                <Typography
                    variant="text-lg-semibold"
                    localClassNames={styles.title}
                >
                    Password
                </Typography>
                <Typography
                    variant="text-sm-regular"
                    localClassNames={styles.instructions}
                >
                    Please enter your current password to change your password.
                </Typography>
            </div>
            <div className={styles.userPasswordsForm}>
                <div className={styles.userPasswordForm}>
                    <div className={styles.formField}>
                        <Typography
                            variant="text-sm-medium"
                            localClassNames={styles.label}
                        >
                            Current password
                        </Typography>
                        <Input
                            inputType="password"
                            placeholder={'••••••••'}
                            strictPasswordLength={false}
                            inputClassname={styles.input}
                            required={false}
                            onChange={(value: string) =>
                                setcurrentPassword(value)
                            }
                            ref={currentPasswordRef}
                        />
                    </div>
                    <div className={styles.formField}>
                        <Typography
                            variant="text-sm-medium"
                            localClassNames={styles.label}
                        >
                            New password
                        </Typography>
                        <Input
                            inputType="password"
                            placeholder={'••••••••'}
                            inputClassname={styles.input}
                            required={false}
                            onChange={(value: string) => setNewPassword(value)}
                            ref={newPasswordRef}
                        />
                    </div>
                    <div className={styles.formField}>
                        <Typography
                            variant="text-sm-medium"
                            localClassNames={styles.label}
                        >
                            Confirm new password
                        </Typography>
                        <Input
                            inputType="password"
                            placeholder={'••••••••'}
                            inputClassname={styles.input}
                            required={false}
                            onChange={(value: string) =>
                                setConfirmedPassword(value)
                            }
                            ref={confirmedPasswordRef}
                        />
                    </div>
                    {errorMessage && (
                        <Typography variant="text-md-regular" color="error-400">
                            {errorMessage}
                        </Typography>
                    )}
                </div>
            </div>
            <div className={styles.actionBtns}>
                <Button
                    styleType="outlineBlack"
                    onClick={handleFormClear}
                    disabled={isLoading}
                >
                    <Typography
                        variant="text-sm-semibold"
                        localClassNames={styles.instructions}
                    >
                        Cancel
                    </Typography>
                </Button>
                <Button
                    styleType="primary"
                    onClick={handleFormSubmit}
                    disabled={isLoading}
                >
                    <Typography
                        variant="text-sm-semibold"
                        localClassNames={styles.instructions}
                    >
                        Update password
                    </Typography>
                </Button>
            </div>
        </div>
    );
};
