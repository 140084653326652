import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const Scroll: React.FC = () => {
    const location = useLocation();

    useEffect(() => {
        window.scroll(0, 0);
    }, [location]);

    return <></>;
};
