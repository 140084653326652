import React from 'react';

import { Typography } from '../../../common';

import styles from './InstructionsGrid.module.scss';
import { CursorBox, ImageIndent, Mail, TextInput } from '../../../assets';

export const InstructionsGrid: React.FC = () => {
    return (
        <div className={styles.instructionsGridContainer}>
            <div className={styles.row}>
                <div className={styles.column}>
                    <CursorBox className={styles.icon} />
                    <Typography variant="text-xl-medium">
                        Click on &#39;Generate a mock paper&#39;
                    </Typography>
                </div>
                <div className={styles.column}>
                    <TextInput className={styles.icon} />
                    <Typography variant="text-xl-medium">
                        Input your choices and preferences
                    </Typography>
                </div>
                <div className={styles.column}>
                    <Mail className={styles.icon} />
                    <Typography variant="text-xl-medium">
                        Settle payment and check your mail in 24-48 hours
                    </Typography>
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.column}>
                    <ImageIndent className={styles.icon} />
                    <Typography variant="text-xl-medium">
                        Get answers and personalized feedbacks after solving the
                        paper
                    </Typography>
                </div>
            </div>
        </div>
    );
};
