import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography } from '../../common';
import { Navbar, PageTitle } from '../../components';
import { PapersModalFlow, QuizzesModalFlow } from './Modal';
import { ChevronRight, DClock, FilePlus } from '../../assets';

import styles from './Practice.module.scss';

export const Practice: React.FC = () => {
    const navigate = useNavigate();

    const [papersModalOpened, setPapersModalOpened] = useState<boolean>(false);
    const [quizzesModalOpened, setQuizzesModalOpened] =
        useState<boolean>(false);

    const handleNavigateToPapers = () => {
        setPapersModalOpened(true);
    };

    const handleNavigateToQuizzes = () => {
        setQuizzesModalOpened(true);
    };

    return (
        <>
            <Navbar loggedIn={true} />
            <div className={styles.mainContainer}>
                <PageTitle title="PRACTICE" back_to={'/dashboard'} />
                <div style={{ paddingBottom: '58px' }}>
                    <Typography
                        variant="title-lg-medium"
                        color="grey-gradient"
                        alignment="center"
                    >
                        Practice makes perfect.
                    </Typography>
                </div>

                {/* <div className={styles.oliviaPoster}>
                    <div className={styles.CommonPadding}>
                        <div className={styles.parentItem}>
                            <Typography
                                variant="title-2xl-semibold"
                                color="white"
                            >
                                Olivia Wilde
                            </Typography>
                            <p className={styles.congresText}>
                                Congratulations on being the top-scorer in
                                Physics quiz with a whopping <span>99%</span>{' '}
                                this <p>week!</p>
                            </p>

                            <Button
                                onClick={() => navigate('/measurement')}
                                styleType="secondary"
                                className={styles.courseButton}
                            >
                                <Typography
                                    variant="text-md-semibold"
                                    color="black"
                                >
                                    Try beating Oliviva&apos;s score
                                </Typography>
                            </Button>
                        </div>
                    </div>
                </div> */}
                <div className={styles.daysPoster}>
                    <div className={styles.items}>
                        {/* <div>
                            <img src={image} alt="" />
                        </div> */}
                        <div className={styles.textParent}>
                            {/* <span className={styles.daysText}> days</span>
                            <span className={styles.daysText}>streak</span> */}
                            <div className={styles.daysText}>
                                Get your streak up
                            </div>
                            <span className={styles.goingText}>
                                Little bit everyday - keep it rolling
                            </span>
                        </div>
                    </div>
                </div>
                <div
                    className={styles.mockPapersBtn}
                    onClick={() => {
                        navigate('/personalized/mock');
                    }}
                >
                    <FilePlus className={styles.icon} />
                    <div>
                        <Typography variant="title-sm-medium">
                            Create a
                        </Typography>
                        <Typography variant="title-lg-semibold">
                            Personalized Mock Paper
                        </Typography>
                    </div>
                </div>
                <div className={styles.quizSection}>
                    <div
                        className={styles.quizBtn}
                        onClick={handleNavigateToQuizzes}
                    >
                        <Typography variant="title-sm-medium" color="white">
                            Take a
                        </Typography>
                        <Typography variant="title-xl-semibold" color="white">
                            Topical Quiz
                        </Typography>
                    </div>
                    <div className={styles.timerBtn}>
                        <Typography variant="title-sm-medium">
                            Coming soon...
                        </Typography>
                        <Typography variant="title-xl-semibold">
                            Stu
                            <DClock className={styles.icon} />y timer
                        </Typography>
                    </div>
                </div>
                <div
                    className={styles.pastPaper}
                    onClick={handleNavigateToPapers}
                    style={{ marginTop: '28px' }}
                >
                    <div className={styles.practicePrant}>
                        <div className={styles.titleMedium}>
                            Practice past papers
                        </div>
                        <div className={styles.btnArrow}>
                            <ChevronRight className={styles.icon} />
                        </div>
                    </div>
                </div>

                {papersModalOpened && (
                    <PapersModalFlow chk={setPapersModalOpened} pp={true} />
                )}
                {quizzesModalOpened && (
                    <QuizzesModalFlow chk={setQuizzesModalOpened} pp={false} />
                )}
            </div>
        </>
    );
};
