import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { UserAccountDetails, UserData } from './types';

interface UserState {
    user: UserData | null;
}

const initialState: UserState = {
    user: null,
};

const userSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCredentials: (state, action) => {
            const { user } = action.payload;
            state.user = user;
        },
        logOut: (state) => {
            state.user = null;
        },
        setUpdatedUserDetails: (
            state,
            action: PayloadAction<{ updatedDetails: UserAccountDetails }>
        ) => {
            const { updatedDetails } = action.payload;
            const { firstName, lastName, email } = updatedDetails;
            if (state.user) {
                if (firstName) {
                    state.user.firstName = firstName;
                }
                if (lastName) {
                    state.user.lastName = lastName;
                }
                if (email) {
                    state.user.email = email;
                }
            }
        },
    },
});

export const { setCredentials, logOut, setUpdatedUserDetails } =
    userSlice.actions;

export default userSlice.reducer;

export const selectCurrentUser = (state: RootState): UserData | null =>
    state.auth.user;
