import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import './App.scss';
import { Layout } from './components';
import { RequireAuth } from './features/auth/RequireAuth';
import { SignedOutRedirect } from './features/redirects';
import { DemoRedirect } from './features/redirects';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
import {
    BiteSizedRecap,
    Chapters,
    CoursePage,
    Courses,
    Dashboard,
    Error404NotFound,
    Home,
    Lessons,
    Login,
    PersonalizedPaper,
    Practice,
    Premium,
    RelatedQuestions,
    Settings,
    Signup,
    SubLesson,
} from './pages';
import { Casual, Competitive, Score } from './pages/Practice/QuizSection';
import { PaperPage } from './pages/Practice/PaperSection/PaperPage';
import ViewPaper from './pages/Practice/PaperSection/ViewPaper';
import MarkScheme from './pages/Practice/PaperSection/MarkScheme';
import SplitView from './pages/Practice/PaperSection/SplitView';
import { Papers } from './pages/Practice/Papers';
import { Topics } from './pages/Practice/Topics';
import { initializeGA, TrackedRoute } from './utils/google-analytics';

const App: React.FC = () => {
    // initialize google analytics for tracking
    initializeGA();

    return (
        <Routes>
            <Route path="/" element={<Layout />}>
                {/* public routes */}
                {/* <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} /> */}

                <Route path="/demo" element={<DemoRedirect />}>
                    <Route index element={<Home />} />
                </Route>

                {/* Signed in redirects */}
                <Route element={<SignedOutRedirect />}>
                    <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route
                        index
                        element={
                            <TrackedRoute>
                                <Home />
                            </TrackedRoute>
                        }
                    />
                </Route>

                {/* signed in protected routes */}
                <Route element={<RequireAuth />}>
                    <Route path="/settings" element={<Settings />} />
                    <Route
                        path="/premium"
                        element={
                            <TrackedRoute>
                                <Premium />
                            </TrackedRoute>
                        }
                    />
                    <Route
                        path="/dashboard"
                        element={
                            <TrackedRoute>
                                <Dashboard />
                            </TrackedRoute>
                        }
                    />

                    {/* Personalized Mock */}
                    <Route
                        path="/personalized/mock"
                        element={<PersonalizedPaper />}
                    />

                    {/* Learn section routes */}
                    <Route
                        path="/learn"
                        element={
                            <TrackedRoute>
                                <Courses />
                            </TrackedRoute>
                        }
                    />
                    <Route path="/learn/:course" element={<CoursePage />} />
                    <Route
                        path="/learn/:course/chapters"
                        element={<Chapters />}
                    />
                    <Route
                        path="/learn/:course/chapters/:chapter/lessons"
                        element={<Lessons />}
                    />
                    <Route
                        path="/learn/:course/chapters/:chapter/lessons/:lesson/questions"
                        element={<RelatedQuestions />}
                    />
                    <Route
                        path="/learn/:course/chapters/:chapter/lessons/:lesson/sub-lessons/:sublesson"
                        element={<SubLesson />}
                    />
                    <Route
                        path="/learn/:course/chapters/:chapter/lessons/:lesson/recap"
                        element={<BiteSizedRecap />}
                    />

                    {/* Practice section routes */}
                    <Route
                        path="/practice"
                        element={
                            <TrackedRoute>
                                <Practice />
                            </TrackedRoute>
                        }
                    />
                    <Route
                        path="/papers/:curriculum/:level/:subject"
                        element={<Papers />}
                    />
                    <Route
                        path="/papers/:curriculum/:level/:subject/:paperIndex"
                        element={<PaperPage />}
                    />
                    <Route
                        path="/papers/:curriculum/:level/:subject/:paperIndex/view-paper"
                        element={<ViewPaper />}
                    />
                    <Route
                        path="/papers/:curriculum/:level/:subject/:paperIndex/mark-scheme"
                        element={<MarkScheme />}
                    />
                    <Route
                        path="/papers/:curriculum/:level/:subject/:paperIndex/split-view"
                        element={<SplitView />}
                    />

                    {/* Quizzes routes */}
                    <Route
                        path="/quizzes/competitive/:curriculum/:level/:subject"
                        element={<Competitive />}
                    />
                    <Route
                        path="/quizzes/casual/:curriculum/:level/:subject"
                        element={<Topics />}
                    />
                    <Route
                        path="/quizzes/casual/:curriculum/:level/:subject/:topic"
                        element={<Casual />}
                    />
                    <Route path="/score" element={<Score />} />

                    {/* Old past paper routes */}
                    {/* <Route
                        path="/paper"
                        element={
                            location.state !== null ? (
                                <PaperPage />
                            ) : (
                                <Navigate to="/practice" replace />
                            )
                        }
                    />
                    <Route path="/paper/view-paper" element={<ViewPaper />} />
                    <Route path="/paper/question" element={<QuestionPaper />} />
                    <Route path="/paper/mark-scheme" element={<MarkScheme />} />
                    <Route path="/paper/split-view" element={<SplitView />} /> */}
                </Route>

                {/* Terms and privacy static document routes */}
                {/* <Route path="/assets/documents/Hexa-Privacy-Policy.pdf" />
                <Route path="/assets/documents/Hexa-Terms-of-Use.pdf" /> */}

                {/* error routes */}
                <Route path="/not-found" element={<Error404NotFound />} />
                <Route
                    path="*"
                    element={<Navigate to="/not-found" replace />}
                />
            </Route>
        </Routes>
    );
};

export default App;
