import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Fuse, { FuseResult } from 'fuse.js';
import styles from './Topics.module.scss';
import { Navbar, PageTitle } from '../../../components';
import { Input, Typography } from '../../../common';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
import { quizTopicMappings } from '../../../data';

const quizTopics: string[] = Array.from(quizTopicMappings.keys());

export const Topics: React.FC = () => {
    const params = useParams<{
        curriculum: string;
        level: string;
        subject: string;
    }>();
    const navigate = useNavigate();

    const [filteredTopics, setFilteredTopics] = useState<FuseResult<string>[]>(
        []
    );

    const options = {
        isCaseSensitive: false,
        threshold: 0.45, // value between 0-1, where lower means more precise matching and higher means less precise matching
        findAllMatches: true,
        shouldSort: true,
        includeScore: false,
        minMatchCharLength: 1,
        includeMatches: false,
        keys: ['title'],
        // keys: ['session', 'year', 'variant', 'a.a', 'a.b.c'], // object's keys to process when searching array of objects
    };
    const fuse = new Fuse(quizTopics, options);

    const handleSearch = (searchStr: string) => {
        if (searchStr !== '') {
            setFilteredTopics(fuse.search(searchStr));
        } else {
            setFilteredTopics(
                quizTopics
                    .sort()
                    .map((topic, i) => ({ item: topic, refIndex: i }))
            );
        }
    };

    useEffect(() => {
        setFilteredTopics(
            quizTopics.sort().map((topic, i) => ({ item: topic, refIndex: i }))
        );
    }, []);

    return (
        <div className={styles.topicSelectionContainer}>
            <Navbar loggedIn={true} />
            <div className={styles.header}>
                <PageTitle back_to="/practice" title="TOPICS" />
                <Typography
                    variant="title-lg-medium"
                    localClassNames={styles.title}
                >
                    Let&#39;s choose a topic
                </Typography>
            </div>
            <div className={styles.topicSearch}>
                <div className={styles.searchBar}>
                    <SearchIcon className={styles.searchIcon} />
                    <Input
                        inputType={'text'}
                        placeholder={'Search topics'}
                        required={false}
                        inputClassname={styles.searchInput}
                        onChange={handleSearch}
                    />
                </div>
                <div className={styles.topics}>
                    {filteredTopics.map(
                        (topic: FuseResult<string>, i: number) => (
                            <div
                                className={styles.topic}
                                onClick={() => {
                                    navigate(
                                        `/quizzes/casual/${params.curriculum}/${
                                            params.level
                                        }/${
                                            params.subject
                                        }/${quizTopicMappings.get(topic.item)}`
                                    );
                                }}
                                key={i}
                            >
                                <Typography variant="text-md-regular">
                                    {topic.item}
                                </Typography>
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    );
};
