import React from 'react';
import { HomeBanner, Learning, Paper } from '../../components';
import CinematicVideo from '../../components/Home/CinematicVideo';
import { Moving } from '../../components/Home/Moving';
import TopicalVideo from '../../components/Home/TopicalVideo';
import HomeNavbar from '../../components/HomeNavbar';
import styles from './Home.module.scss';

export const Home: React.FC = () => {
    return (
        <div className={styles.homeContainer}>
            <div className={styles.fadeInAnimation}>
                <HomeNavbar />
                <HomeBanner />
                <CinematicVideo />
                <Moving />
                <TopicalVideo />
                <Paper />
                <Learning />
            </div>
        </div>
    );
};
