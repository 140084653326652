import React from 'react';

import { Button, Modal, Typography } from '../../../common';

import styles from './FeedbackModals.module.scss';

interface Props {
    closeFn: () => void;
}

export const FeedbackModals: React.FC<Props> = ({ closeFn }) => {
    return (
        <div className={styles.feedbackModals}>
            <Modal closeFn={closeFn}>
                <div className={styles.textBlock}>
                    <Typography
                        variant="title-lg-semibold"
                        localClassNames={styles.centerHeader}
                    >
                        Need paper check and feedbacks?
                    </Typography>
                    <Typography variant="text-xl-regular">
                        Upload and send your solved mock paper attachment by
                        replying on the <strong>same email thread</strong> as
                        the one where you were sent your personalized mock
                        paper.
                    </Typography>
                    <Typography variant="text-xl-regular">
                        Our academic team will take a look and provide you a
                        checked paper, with feedbacks, within 48-72 hours.
                    </Typography>
                </div>
                <div className={styles.actionBtns}>
                    <Button styleType="primary" onClick={closeFn}>
                        <Typography variant="text-md-semibold">
                            Got it
                        </Typography>
                    </Button>
                </div>
            </Modal>
        </div>
    );
};
