import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { Typography } from '../Typography';

import styles from './Input.module.scss';

export interface InputProps {
    inputType: string;
    required: boolean;
    placeholder?: string;
    inputClassname?: string;
    containerClassname?: string;
    disabled?: boolean;
    disableValidation?: boolean;
    strictPasswordLength?: boolean; // allows backward compatibility for short passwords of initial users
    // Remove the 'ref' prop from here
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
    onChange?: (value: any) => void;
}

// Forward the ref to the input element
export const Input = React.forwardRef<HTMLFormElement, InputProps>(
    (
        {
            inputType,
            required,
            placeholder,
            inputClassname,
            containerClassname,
            disableValidation,
            strictPasswordLength = true,
            onChange,
        },
        ref
    ) => {
        const inputRef = useRef<HTMLInputElement>(null);

        const [inputValue, setInputValue] = useState('');
        const [isValid, setIsValid] = useState(true);

        const [errorValue, setErrorValue] = useState('Invalid');
        const [showError, setShowError] = useState(false);

        const handleInputBlur = () => {
            if (!disableValidation) {
                setShowError(!isValid);
            }
        };

        const handleInputChange = (
            e: React.ChangeEvent<HTMLInputElement> | string
        ) => {
            const value = typeof e === 'string' ? e : e.target.value;
            setInputValue(value);

            // Perform validation if the input type is email
            if (inputType === 'email') {
                setIsValid(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value));
            } else if (inputType === 'tel') {
                setIsValid(/^(?:\+8801|8801|01)\d{9}$/.test(value));
            } else if (inputType === 'password' && strictPasswordLength) {
                setIsValid(value.length > 8);
                setErrorValue('Ensure password is more than 8 characters');
            }

            if (!inputValue) {
                setShowError(false);
            }

            // // Call the passed onChange if any
            // if (onChange && isValid && inputValue) {
            //     setShowError(!isValid);
            //     onChange(e);
            // }
        };

        const handleInputReset = (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault;
            handleInputChange('');
            setShowError(false);
        };

        useEffect(() => {
            if (onChange && isValid) {
                setShowError(!isValid);
                onChange(inputValue.trim());
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [isValid, inputValue]);

        return (
            <form
                className={classNames(styles.input, containerClassname)}
                onResetCapture={handleInputReset}
                ref={ref}
            >
                <input
                    type={inputType}
                    required={required}
                    placeholder={placeholder}
                    className={classNames(
                        styles.primaryInputContainer,
                        inputClassname
                    )}
                    autoComplete="new-password"
                    autoCorrect="off"
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    ref={inputRef}
                />
                {showError && (
                    <Typography variant="text-sm-regular" color="error-400">
                        {errorValue}
                    </Typography>
                )}
            </form>
        );
    }
);

// Add a displayName property
Input.displayName = 'Input';

export default Input;
