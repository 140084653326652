import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';

import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { Button, Typography } from '../../../../common';
import { Navbar, PageTitle } from '../../../../components';
import { FullScreen } from '../../../../assets';
import { QuestionModal, QuitQuizModal } from '../Modals';
import { getRandomQuizzes } from '../../../../data';

import styles from './Competitive.module.scss';

const competitiveQuizList = getRandomQuizzes();

export const Competitive: React.FC = () => {
    const params = useParams<{
        quizMode: string;
        curriculum: string;
        level: string;
        subject: string;
        topic: string;
    }>();
    const navigate = useNavigate();

    const [showCountdown, setShowCountdown] = useState(true);
    const [countdown, setCountdown] = useState(3);
    const time = useMemo(() => 180, []);
    const [startTimer, setStartTimer] = useState<boolean>(false);

    const [currentQuestion, setCurrentQuestion] = useState<number>(0);
    const [selectedAnswer, setSelectedAnswer] = useState<(string | null)[]>(
        new Array(competitiveQuizList.length)
    );
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [clickCount, setClickCount] = useState<number>(0);

    const [questionModalOpened, setQuestionModalOpened] =
        useState<boolean>(false);
    const [endModalOpened, setEndModalOpened] = useState<boolean>(false);

    const handleQuizEnd = () => {
        // Perform any logic needed when ending the quiz
        // For example, update state or send data to a server
        const totalQuestions: number = competitiveQuizList.length;
        let correctCount: number = 0;
        let wrongCount: number = 0;

        for (let i: number = 0; i < totalQuestions; i++) {
            if (
                selectedAnswer[i]?.toUpperCase() ===
                competitiveQuizList[i].answer.toUpperCase()
            ) {
                correctCount++;
            } else {
                wrongCount++;
            }
        }

        const percentageCorrect: number = (correctCount / totalQuestions) * 100;
        const percentageWrong: number = (wrongCount / totalQuestions) * 100;

        navigate('/score', {
            state: {
                correctParcentage: Math.round(percentageCorrect),
                percentageWrong: Math.round(percentageWrong),
            },
        });
    };

    const handleNextQuestion = () => {
        if (currentQuestion < competitiveQuizList.length - 1) {
            setCurrentQuestion((prev) => prev + 1);
        } else {
            handleQuizEnd();
        }
    };

    const handlePrevQuestion = () => {
        if (currentQuestion > 0) {
            setCurrentQuestion((prev) => prev - 1);
        }
    };

    const handleAnswerSelect = (answer: string) => {
        // Disable selecting a new answer if one is already selected
        if (
            answer.toUpperCase() !==
            selectedAnswer[currentQuestion]?.toUpperCase()
        ) {
            setSelectedAnswer((prevArr) => {
                const updatedArr = prevArr;

                updatedArr[currentQuestion] = answer;

                return updatedArr;
            });

            setClickCount((prevVal) => prevVal + 1);
            // console.log(clickCount);
        }
    };

    const toggleEndModal = () => {
        setEndModalOpened((prevVal) => !prevVal);
    };

    const handleQuitQuiz = () => {
        handleQuizEnd();
    };

    useEffect(() => {
        const timer = setInterval(() => {
            if (countdown > 0) {
                setCountdown((prev) => prev - 1);
            } else {
                setShowCountdown(false);
                setStartTimer(true); // Trigger the CountdownCircleTimer
                clearInterval(timer);
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [countdown]);

    return (
        <div>
            <Navbar loggedIn={true} />
            <div className={styles.mainContainer}>
                {showCountdown && (
                    <div className={styles.countdownOverlay}>
                        <p className={styles.countdownText}>
                            {countdown == 0 ? 'Go!' : countdown}
                        </p>
                    </div>
                )}
                {questionModalOpened && (
                    <QuestionModal
                        chk={setQuestionModalOpened}
                        img={competitiveQuizList[currentQuestion].img}
                    />
                )}
                {endModalOpened && (
                    <QuitQuizModal
                        onEndQuiz={handleQuitQuiz}
                        onCancel={toggleEndModal}
                    />
                )}

                <PageTitle title={'quiz'} />
                <div className={styles.titleCard}>
                    <div>
                        <Typography
                            variant="title-sm-medium"
                            color="white"
                            alignment="center"
                        >
                            {params.topic &&
                                params.topic
                                    .split(' ')
                                    .map(
                                        (word) =>
                                            word.charAt(0).toUpperCase() +
                                            word.slice(1).toLowerCase()
                                    )
                                    .join(' ')}
                        </Typography>
                        <Typography
                            variant="text-md-regular"
                            localClassNames={styles.questionCount}
                        >
                            Question ({currentQuestion + 1}/
                            {competitiveQuizList.length})
                        </Typography>
                    </div>
                </div>
                <div className={styles.testContainer}>
                    <div className={styles.question}>
                        <div className={styles.scrollContainer}>
                            <img
                                src={competitiveQuizList[currentQuestion].img}
                                alt="question photo"
                                className={styles.questionImg}
                            />
                        </div>
                        <FullScreen
                            className={styles.fullScreenIcon}
                            onClick={() => setQuestionModalOpened(true)}
                        />
                    </div>
                    <div className={styles.answer}>
                        <CountdownCircleTimer
                            isPlaying={startTimer}
                            duration={time}
                            colors={[
                                '#0c7b9f',
                                '#0c7b9f',
                                '#0c7b9f',
                                '#0c7b9f',
                                '#0c7b9f',
                            ]}
                            onComplete={handleQuizEnd}
                            colorsTime={[7, 5, 2, 0]}
                            strokeWidth={18}
                        >
                            {({ remainingTime }) => (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <span className={styles.timeLeft}>
                                        Time left:
                                    </span>
                                    <h1
                                        style={{
                                            paddingLeft: 4,
                                            color: '#164C63',
                                        }}
                                    >
                                        {Math.floor(remainingTime / 60)}:
                                        {(remainingTime % 60)
                                            .toString()
                                            .padStart(2, '0')}
                                    </h1>
                                </div>
                            )}
                        </CountdownCircleTimer>
                        <div className={styles.selectionSection}>
                            <Typography
                                variant="text-md-regular"
                                localClassNames={styles.text}
                            >
                                Select your answer
                            </Typography>
                            <div className={styles.answerBtns}>
                                {['A', 'B', 'C', 'D'].map((key) => (
                                    <button
                                        key={key.toUpperCase()}
                                        className={classNames(
                                            styles.answerBtn,
                                            selectedAnswer[
                                                currentQuestion
                                            ]?.toUpperCase() !==
                                                key.toUpperCase()
                                                ? styles.deselected
                                                : styles.selected
                                        )}
                                        onClick={() => handleAnswerSelect(key)}
                                    >
                                        {key.toUpperCase()}
                                    </button>
                                ))}
                            </div>
                        </div>
                        <div className={styles.actionBtns}>
                            <Button
                                styleType="tertiary"
                                className={styles.actionBtn}
                                onClick={handlePrevQuestion}
                                disabled={currentQuestion === 0}
                            >
                                <Typography variant="text-md-medium">
                                    Back
                                </Typography>
                            </Button>
                            <Button
                                styleType="tertiary"
                                className={styles.actionBtn}
                                onClick={handleNextQuestion}
                            >
                                <Typography variant="text-md-medium">
                                    {currentQuestion <
                                    competitiveQuizList.length - 1
                                        ? 'Next'
                                        : 'Submit'}
                                </Typography>
                            </Button>
                        </div>
                    </div>
                </div>
                <Button
                    styleType="outlineError"
                    className={styles.end}
                    onClick={toggleEndModal}
                >
                    <Typography variant="text-md-medium">End Quiz</Typography>
                </Button>
            </div>
        </div>
    );
};
