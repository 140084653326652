import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Fuse, { FuseResult } from 'fuse.js';
import { Paper, papers, validatePapers } from '../../../data';
import styles from './Papers.module.scss';
import { Navbar, PageTitle } from '../../../components';
import { Input, Typography } from '../../../common';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';

interface PaperWithIndex extends Paper {
    paperIndex: number;
}

type ProcessedPaper = { title: string; paperIndex: number };

export const Papers: React.FC = () => {
    const params = useParams<{
        curriculum: string;
        level: string;
        subject: string;
    }>();
    const navigate = useNavigate();

    const [subjectCode, setSubjectCode] = useState<number>();
    const [allPapers, setAllPapers] = useState<PaperWithIndex[]>([]);
    const [processedPapers, setProcessedPapers] = useState<ProcessedPaper[]>(
        []
    );
    const [filteredPapers, setFilteredPapers] = useState<
        FuseResult<ProcessedPaper>[]
    >([]);

    const options = {
        isCaseSensitive: false,
        threshold: 0.45, // value between 0-1, where lower means more precise matching and higher means less precise matching
        findAllMatches: true,
        shouldSort: true,
        includeScore: false,
        minMatchCharLength: 1,
        includeMatches: false,
        keys: ['title'],
        // keys: ['session', 'year', 'variant', 'a.a', 'a.b.c'], // object's keys to process when searching array of objects
    };
    const fuse = new Fuse(processedPapers, options);

    const handleSearch = (searchStr: string) => {
        if (searchStr !== '') {
            // setFilteredPapers(fuse.search(searchStr).slice(0, 10));
            setFilteredPapers(fuse.search(searchStr));
        }
    };

    useEffect(() => {
        if (params.curriculum && params.level && params.subject) {
            if (
                !validatePapers(params.curriculum, params.level, params.subject)
            ) {
                navigate('/not-found');
            }
        }
    }, [params, navigate]);

    useEffect(() => {
        // if (
        //     !(
        //         state === null ||
        //         state.curriculum === null ||
        //         state.level === null ||
        //         state.subject === null
        //     )
        // ) {
        //     const subject =
        //         papers[state.curriculum.toLowerCase()][
        //             state.level.toLowerCase()
        //         ][state.subject.toLowerCase()];
        //     const filteredPapers: Paper[] = subject.papers;

        //     setSubjectCode(subject.subjectcode);
        //     setAllPapers(
        //         filteredPapers.map((paper, i) => ({ ...paper, paperIndex: i }))
        //     );
        // }

        if (
            params.curriculum &&
            params.level &&
            params.subject &&
            validatePapers(params.curriculum, params.level, params.subject)
        ) {
            const subjectObj =
                papers[params.curriculum.toLowerCase()][
                    params.level.toLowerCase()
                ][params.subject.toLowerCase()];
            const filteredPapers: Paper[] = subjectObj.papers;

            setSubjectCode(subjectObj.subjectcode);
            setAllPapers(
                filteredPapers.map((paper, i) => ({ ...paper, paperIndex: i }))
            );
        }
    }, [subjectCode, params]);

    useEffect(() => {
        setProcessedPapers(
            allPapers.map((paper, i) => ({
                title: `${
                    params.subject && params.subject.charAt(0).toUpperCase()
                }${
                    params.subject && params.subject.slice(1).toLowerCase()
                } ${subjectCode} - ${paper.session} ${paper.year} - Paper ${
                    paper.variant
                }`,
                paperIndex: i,
            }))
        );
    }, [subjectCode, allPapers, params.subject]);

    useEffect(() => {
        setFilteredPapers(
            // processedPapers.slice(0, 10).map(
            processedPapers.map(
                (paper, i) =>
                    ({
                        item: { ...paper },
                        refIndex: i,
                    }) as FuseResult<ProcessedPaper>
            )
        );
    }, [processedPapers]);

    return (
        <div className={styles.paperSelectionContainer}>
            <Navbar loggedIn={true} />
            <div className={styles.header}>
                <PageTitle back_to="/practice" title="PAST PAPERS" />
                <Typography
                    variant="title-lg-medium"
                    localClassNames={styles.title}
                >
                    Let&#39;s choose a paper
                </Typography>
            </div>
            <div className={styles.paperSearch}>
                <div className={styles.searchBar}>
                    <SearchIcon className={styles.searchIcon} />
                    <Input
                        inputType={'text'}
                        placeholder={'Search papers'}
                        required={false}
                        inputClassname={styles.searchInput}
                        onChange={handleSearch}
                    />
                </div>
                <div className={styles.papers}>
                    {filteredPapers.map(
                        (paper: FuseResult<ProcessedPaper>, i: number) => (
                            <div
                                className={styles.paper}
                                onClick={() => {
                                    navigate(
                                        `/papers/${params.curriculum}/${params.level}/${params.subject}/${paper.item.paperIndex}`
                                    );
                                }}
                                key={i}
                            >
                                <Typography variant="text-md-regular">
                                    {paper.item.title}
                                </Typography>
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    );
};
