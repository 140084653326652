import React, { useState } from 'react';
// import { HexaBetaLogo } from '../../assets';
import { Link } from 'react-router-dom';
import Quantum from '../../../assets/home/Quantum.png';
import Velocity from '../../../assets/home/Velocity.png';
// import { ReactComponent as Quantum } from '../../../assets/home/Quantum.svg';
// import { ReactComponent as Velocity } from '../../../assets/home/Velocity.svg';
import { Button, Input, Typography } from '../../../common';
import styles from './HomeBanner.module.scss';

export const HomeBanner: React.FC = () => {
    const [isModalVisibleVideo, setModalVisibleVideo] = useState(false);
    const [isModalVisibleSignUp, setModalVisibleSignUp] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    // const trailerVideo = 'https://youtu.be/iKYu2DItKk4?si=V1QFib1fxGsn3wAu';

    const openModalVideo = () => {
        setModalVisibleVideo(true);
    };

    // const openModalSignUp = () => {
    //     setModalVisibleSignUp(true);
    // };

    const closeModalVideo = () => {
        setModalVisibleVideo(false);
    };

    const closeModalSignUp = () => {
        setModalVisibleSignUp(false);
        setIsSubmit(false);
    };

    return (
        <>
            <div className={styles.bannerContainer}>
                <div className={styles.dualBackground}>
                    <div className={styles.bannerLaptop}>
                        <div
                            // data-aos="fade-up"
                            className={styles.bannerHeading}
                        >
                            <Typography variant="title-2xl-semibold">
                                The most stylish learning <br /> experience.
                                Ever.
                            </Typography>
                            <Typography variant="text-xl-regular" color="white">
                                Making high school exam preparations fun,
                                delightful, and cinematic.
                            </Typography>
                        </div>
                        <div className={styles.bannerButtonContainer}>
                            <Link className={styles.navLink} to="/login">
                                <Button
                                    styleType="primary"
                                    className={styles.getButton}
                                    // onClick={openModalSignUp}
                                >
                                    <Typography variant="text-md-semibold">
                                        Get Started
                                    </Typography>
                                </Button>
                            </Link>
                            <Button
                                styleType="outline"
                                className={styles.watchButton}
                                onClick={openModalVideo}
                            >
                                <Typography variant="text-md-semibold">
                                    Watch Physics trailer
                                </Typography>
                                <Typography variant="text-md-semibold">
                                    {' '}
                                    ▶︎{' '}
                                </Typography>
                            </Button>
                        </div>
                        <div className={styles.awardContainer}>
                            <img
                                // data-aos="fade-left"
                                className={styles.award}
                                src={Quantum}
                                alt=""
                            />
                            <img
                                // data-aos="fade-right"
                                className={styles.award}
                                src={Velocity}
                                alt=""
                            />
                            {/* <Quantum
                                className={styles.award}
                                // data-aos="fade-left"
                            />
                            <Velocity
                                className={styles.award}
                                // data-aos="fade-right"
                            /> */}
                            <div className={styles.awardGradient} />
                        </div>
                    </div>
                </div>
                <div className={styles.bannerBottomHeading}>
                    <Typography variant="title-3xl-semibold">
                        <span className={styles.heading1}>Learn</span>{' '}
                        <span className={styles.heading3}>and</span>{' '}
                        <span className={styles.heading2}>
                            Practice. <br />{' '}
                        </span>{' '}
                        <span className={styles.heading3}>
                            Two birds with one <br />{' '}
                        </span>{' '}
                        <span className={styles.heading4}>stone.</span>
                    </Typography>
                </div>
            </div>
            {isModalVisibleSignUp && (
                <div className={`${styles.modal} ${styles.visible}`}>
                    <div className={styles.modalContainer}>
                        {isSubmit ? (
                            <div className={styles.completedContainer}>
                                <div className={styles.contentContainer}>
                                    <Typography
                                        variant="title-xl-semibold"
                                        color="white"
                                    >
                                        Completed!
                                    </Typography>
                                    <Typography
                                        variant="text-xl-regular"
                                        color="grey"
                                        alignment="center"
                                    >
                                        Thank you for showing your interest in
                                        the beta launch. We’ll get back to you
                                        shortly whenever appropriate.
                                    </Typography>
                                    <div className={styles.formContainer}>
                                        <div
                                            className={styles.continueButtonDiv}
                                        >
                                            <Button
                                                onClick={closeModalSignUp}
                                                styleType="outline"
                                                className={
                                                    styles.continueButton
                                                }
                                                // onClick={handleLogin}
                                            >
                                                <Typography
                                                    variant="text-md-semibold"
                                                    color="white"
                                                >
                                                    Go back to Homepage
                                                </Typography>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    className={styles.closeBtn}
                                    onClick={closeModalSignUp}
                                >
                                    &times;
                                </button>
                            </div>
                        ) : (
                            <div className={styles.homeSignUpContainer}>
                                <div className={styles.contentContainer}>
                                    <Typography
                                        variant="title-xl-semibold"
                                        color="white"
                                    >
                                        Sign up for Beta
                                    </Typography>
                                    <Typography
                                        variant="text-xl-regular"
                                        color="grey"
                                    >
                                        Take an exclusive first look into the
                                        product
                                    </Typography>
                                    <div className={styles.formContainer}>
                                        <Input
                                            inputType="text"
                                            required={true}
                                            placeholder="Enter your full name"
                                            inputClassname={styles.nameInput}
                                            // onChange={handleEmailInput}
                                        />
                                        <Input
                                            inputType="email"
                                            required={true}
                                            placeholder="Enter your email"
                                            inputClassname={styles.emailInput}
                                            // onChange={handleEmailInput}
                                        />

                                        <div
                                            className={styles.continueButtonDiv}
                                        >
                                            <Button
                                                onClick={() =>
                                                    setIsSubmit(true)
                                                }
                                                styleType="primary"
                                                className={
                                                    styles.continueButton
                                                }
                                                // onClick={handleLogin}
                                            >
                                                <Typography
                                                    variant="text-md-semibold"
                                                    color="white"
                                                >
                                                    Submit
                                                </Typography>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    className={styles.closeBtn}
                                    onClick={closeModalSignUp}
                                >
                                    &times;
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            )}
            {isModalVisibleVideo && (
                <div
                    onClick={closeModalVideo}
                    className={`${styles.modal} ${styles.visible}`}
                >
                    <div className={styles.modalContainer}>
                        <div className={styles.videoContainer}>
                            <iframe
                                className={styles.youtubeEmbed}
                                // width="560"
                                // height="315"
                                src="https://hexa-content.s3.ap-southeast-1.amazonaws.com/Trailers/CIE+Physics+O+Levels+-+Trailer.mp4"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
