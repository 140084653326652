import React from 'react';

import { Dropdown, Typography } from '../../../../common';
import image4 from '../../../../assets/Practice/whiteicon.png';
import ximg from '../../../../assets/Practice/x-close.png';

import styles from './modals.module.scss';

interface Step1Props {
    onNext: (mode: string) => void;
    chk: (value: boolean) => void;
}

export const Step1: React.FC<Step1Props> = ({ onNext, chk }) => {
    return (
        <div className={styles.modalContainer}>
            <div className={styles.titleCloseBtn}>
                <button onClick={() => chk(false)}>
                    {' '}
                    <img src={ximg} alt="ximage" />{' '}
                </button>
            </div>
            <Typography
                localClassNames={styles.quiz}
                variant="title-md-semibold"
                color="black"
            >
                Choose quiz <span>mode</span>
            </Typography>
            <div className={styles.modeSelection}>
                <div className={styles.casual} onClick={() => onNext('casual')}>
                    <Typography
                        localClassNames={styles.quiz}
                        variant="title-lg-semibold"
                    >
                        Casual
                    </Typography>
                    <Typography
                        localClassNames={styles.quiz}
                        variant="title-xs-medium"
                    >
                        No timer. No pressure. Practice topic-specific quizzes
                        at your own pace.
                    </Typography>
                </div>
                <div
                    className={styles.competitive}
                    onClick={() => onNext('competitive')}
                >
                    <Typography
                        localClassNames={styles.quiz}
                        variant="title-lg-semibold"
                    >
                        Competitive
                    </Typography>
                    <Typography
                        localClassNames={styles.quiz}
                        variant="title-xs-medium"
                    >
                        50 questions, 180 seconds. Ignite your competitive
                        spirit.
                    </Typography>
                </div>
            </div>
        </div>
    );
};

interface Step2Props {
    curriculum: string;
    level: string;
    setCurriculum: React.Dispatch<React.SetStateAction<string>>;
    setLevel: React.Dispatch<React.SetStateAction<string>>;
    curriculumsList: string[];
    levelsList: string[];
    chk: (value: boolean) => void;
    handleNext: () => void;
}

export const Step2: React.FC<Step2Props> = ({
    curriculum,
    level,
    setCurriculum,
    setLevel,
    curriculumsList,
    levelsList,
    chk,
    handleNext,
}) => {
    const handleCurriculumChange = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        setCurriculum(
            (
                (e.target as HTMLDivElement).parentNode
                    ?.childNodes[0] as HTMLDivElement
            ).innerText.toLowerCase()
        );
    };

    const handleLevelChange = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        setLevel(
            (
                (e.target as HTMLDivElement).parentNode
                    ?.childNodes[0] as HTMLDivElement
            ).innerText.toLowerCase()
        );
    };

    return (
        <div className={styles.step2Model}>
            <div className={styles.titleCloseBtn}>
                <button onClick={() => chk(false)}>
                    <img src={ximg} alt="ximage" />
                </button>
            </div>
            <Typography
                localClassNames={styles.quiz}
                variant="title-md-semibold"
                color="black"
            >
                Choose your curriculum and level
            </Typography>
            <div className={styles.parentSelect}>
                <div className={styles.dropdownOne}>
                    <div className={styles.dropdown}>
                        <Dropdown
                            placeholder={'Select curriculum'}
                            list={curriculumsList}
                            onChange={handleCurriculumChange}
                        />
                    </div>
                </div>
                <div className={styles.dropdownTwo}>
                    <div className={styles.dropdown}>
                        <Dropdown
                            placeholder={'Select level'}
                            list={levelsList}
                            onChange={handleLevelChange}
                        />
                    </div>
                    <div
                        className={styles.pastPaper}
                        onClick={
                            !(curriculum === '' || level === '')
                                ? handleNext
                                : undefined
                        }
                    >
                        <div className={styles.practicePrant}>
                            <div className={styles.circle}>
                                <img src={image4} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

interface Step3Props {
    value: string;
    setValue: React.Dispatch<React.SetStateAction<string>>;
    valuesList: string[];
    handleNext: (mode: string) => void;
    chk: (value: boolean) => void;
}

export const Step3: React.FC<Step3Props> = ({
    value,
    setValue,
    valuesList,
    handleNext,
    chk,
}) => {
    const handleSubjectChange = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        setValue(
            (
                (e.target as HTMLDivElement).parentNode
                    ?.childNodes[0] as HTMLDivElement
            ).innerText.toLowerCase()
        );
    };

    return (
        <div className={styles.step2Model}>
            <div className={styles.titleCloseBtn}>
                <button onClick={() => chk(false)}>
                    <img src={ximg} alt="ximage" />
                </button>
            </div>
            <Typography
                localClassNames={styles.quiz}
                variant="title-md-semibold"
                color="black"
            >
                Choose a subject
            </Typography>

            <div className={styles.parentSelect}>
                <div className={styles.dropdownThree}>
                    <div className={styles.dropdown}>
                        <Dropdown
                            placeholder={'Select subject'}
                            list={valuesList}
                            onChange={handleSubjectChange}
                        />
                    </div>
                </div>
                <div
                    className={styles.pastPaper}
                    onClick={
                        !(value === '')
                            ? () => handleNext('subject')
                            : undefined
                    }
                >
                    <div className={styles.practicePrant}>
                        <div className={styles.circle}>
                            <img src={image4} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

interface Step4Props {
    value: string;
    setValue: React.Dispatch<React.SetStateAction<string>>;
    valuesList: string[];
    handleNext: (mode: string) => void;
    chk: (value: boolean) => void;
    placeholder: string;
    title: string;
}

export const Step4: React.FC<Step4Props> = ({
    value,
    setValue,
    valuesList,
    handleNext,
    chk,
    placeholder,
    title,
}) => {
    const handleTopicChange = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        setValue((e.target as HTMLDivElement).dataset.index ?? '');
    };

    return (
        <div className={styles.step2Model}>
            <div className={styles.titleCloseBtn}>
                <button onClick={() => chk(false)}>
                    {' '}
                    <img src={ximg} alt="ximage" />{' '}
                </button>
            </div>
            <Typography
                localClassNames={styles.quiz}
                variant="title-md-semibold"
                color="black"
            >
                {title}
            </Typography>

            <div className={styles.parentSelect}>
                <div className={styles.dropdownFour}>
                    <div className={styles.dropdown}>
                        <Dropdown
                            placeholder={placeholder}
                            list={valuesList}
                            onChange={handleTopicChange}
                        />
                    </div>
                </div>
                <div
                    className={styles.pastPaper}
                    onClick={
                        !(value === '') ? () => handleNext('paper') : undefined
                    }
                >
                    <div className={styles.practicePrant}>
                        <div className={styles.circle}>
                            <img src={image4} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
