export const quizTopicMappings = new Map<string, string>([
    ['Electromagnetic Spectrum', 'electromagnetic_spectrum'],
    ['Light', 'light'],
    ['Density', 'density'],
    ['Electric Circuits', 'electric_circuits'],
    ['Electrical Quantities', 'electrical_quantities'],
    ['Electromagnetic Effects', 'electromagnetic_effects'],
    ['Electromagnetic Spectrum', 'electromagnetic_spectrum'],
    ['Energy, Work, and Power', 'energy_work_power'],
    ['Force', 'force'],
    ['Kinetics', 'kinetics'],
    ['Mass and Weight', 'mass_weight'],
    ['Momentum', 'momentum'],
    ['Nuclear Model', 'nuclear_model'],
    ['Oscilloscope', 'oscilloscope'],
    ['Physical Quantities', 'physical_quantities'],
    ['Practical Electricity', 'practical_electricity'],
    ['Pressure', 'pressure'],
    ['Radioactivity', 'radioactivity'],
    ['Simple Magnetic Fields', 'simple_magnetic_fields'],
    ['Solar Systems', 'solar_systems'],
    ['Sound', 'sound'],
    ['Stars and Universe', 'stars_universe'],
    ['Thermal Temperature', 'thermal_temperature'],
    ['Transfer of Thermal Energy', 'transfer_thermal_energy'],
    ['Waves', 'waves'],
]);

export const quizTopicNames = new Map<string, string>();

quizTopicMappings.forEach((value, key) => {
    quizTopicNames.set(value, key);
});

export const quizCurriculums: string[] = ['Cambridge'];

export const quizLevels: string[] = ['O-levels'];

export const quizSubjects: string[] = ['Physics'];
