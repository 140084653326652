import React from 'react';
import classNames from 'classnames';

import { ReactComponent as X } from '../../assets/icons/x.svg';

import styles from './Modal.module.scss';

export interface ModalProps {
    className?: string;
    closeFn: () => void;
    children: React.ReactNode;
}

export const Modal: React.FC<ModalProps> = ({
    className,
    closeFn,
    children,
}) => {
    return (
        <div className={classNames(styles.modal, className)}>
            <div className={styles.modalBg} onClick={closeFn} />
            <div className={styles.modalContainer}>
                <X className={styles.closeBtn} onClick={closeFn} />
                <div className={styles.modalContent}>{children}</div>
            </div>
        </div>
    );
};
