import React, { useState } from 'react';

import styles from './SignUpModals.module.scss';

import { ReactComponent as ArrowLeft } from '../../../assets/icons/arrowLeft.svg';
import { Button, Dropdown, Input, Typography } from '../../../common';
import { LoadingModal } from '../LoadingModal';

interface Props {
    isPrivate: string;
    school: string;
    grade: string;
    curriculum: string;
    setIsPrivate: React.Dispatch<React.SetStateAction<boolean>>;
    setSchool: React.Dispatch<React.SetStateAction<string>>;
    setGrade: React.Dispatch<React.SetStateAction<string>>;
    setCurriculum: React.Dispatch<React.SetStateAction<string>>;
    setPageLoader?: React.Dispatch<React.SetStateAction<boolean>>;
    onSubmit: () => void;
}

export const SignUpModals: React.FC<Props> = ({
    isPrivate,
    school,
    grade,
    curriculum,
    setIsPrivate,
    setSchool,
    setGrade,
    setCurriculum,
    setPageLoader,
    onSubmit,
}: Props) => {
    type modalNums = 1 | 2 | 3 | 4 | 5;
    const [modalNum, setModalNum] = useState<modalNums>(1);

    const handleIsPrivate = (bool: boolean) => {
        setIsPrivate(bool);
    };
    const handleSchool = (val: string) => {
        handleIsPrivate(false);
        setSchool(val);
    };
    const handleGrade = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setGrade(
            (
                (e.target as HTMLDivElement).parentNode
                    ?.childNodes[0] as HTMLDivElement
            ).innerText
        );
    };
    const handleCurriculum = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        setCurriculum(
            (
                (e.target as HTMLDivElement).parentNode
                    ?.childNodes[0] as HTMLDivElement
            ).innerText
        );
    };

    const header: (string | null)[] = [
        'Hello Newcomer!',
        // 'Let’s get started',
        'Which school do you study in?',
        'Which grade do you study at currently?',
        'Which curriculum are you a part of?',
        null,
    ];
    const text: (string | null)[] = [
        'Welcome to Hexa, where we try to make learning fun, engaging, and momentously frictionless.',
        // 'Before we proceed, please enter your details below.',
        'If you’re a private student, click the respective option below. ',
        'Knowing your grade level will help us better personalise your experience in the future',
        'Same as your grade level, knowing your curriculum will make future personalisations more streamlined.',
        null,
    ];
    const form: any[] = [
        null,
        <Input
            required={true}
            inputType={'text'}
            placeholder={'Enter the name of your school'}
            inputClassname={styles.input}
            onChange={handleSchool}
            key={2}
        />,
        <Dropdown
            list={[
                'Lower than grade 7',
                'Grade 7',
                'Grade 8',
                'Grade 9',
                'Grade 10',
                'Grade 11',
                'Grade 12',
                'Higher than grade 12',
            ]}
            placeholder={'Select grade'}
            className={styles.dropdown}
            onChange={handleGrade}
            key={3}
        />,
        <Dropdown
            list={[
                'Cambridge',
                'Edexcel',
                'International Baccalaureate',
                'French Baccalaureate',
                'Other',
            ]}
            placeholder={'Select curriculum'}
            className={styles.dropdown}
            onChange={handleCurriculum}
            key={4}
        />,
    ];
    const firstBtnText: (string | null)[] = [
        'Alright, let’s go!',
        // 'Proceed',
        'Proceed',
        'Confirm',
        'Confirm',
        null,
    ];
    const secondBtnText: (string | null)[] = [
        null,
        // null,
        'I’m not in a school currently',
        null,
        null,
        null,
    ];

    const prevModal = () =>
        setModalNum((prevVal: modalNums) => (prevVal - 1) as modalNums);

    const nextModal = (override?: boolean) => {
        if (firstBtnText) {
            switch (modalNum) {
                case 1:
                    setModalNum(
                        (prevVal: modalNums) => (prevVal + 1) as modalNums
                    );
                    break;

                case 2:
                    if ((school !== '' || override) && isPrivate !== null) {
                        setModalNum(
                            (prevVal: modalNums) => (prevVal + 1) as modalNums
                        );
                    }
                    break;

                case 3:
                    if (grade !== '') {
                        setModalNum(
                            (prevVal: modalNums) => (prevVal + 1) as modalNums
                        );
                    }
                    break;

                case 4:
                    if (curriculum !== '') {
                        setModalNum(
                            (prevVal: modalNums) => (prevVal + 1) as modalNums
                        );
                        onSubmit();
                    }
                    break;

                default:
                    setModalNum(
                        (prevVal: modalNums) => (prevVal + 1) as modalNums
                    );
                    break;
            }
        }
    };

    return (
        <>
            {modalNum <= 4 && (
                <div className={styles.signUpModalsContainer}>
                    <div className={styles.textBox}>
                        {header[modalNum - 1] && (
                            <Typography variant="title-xl-semibold">
                                {header[modalNum - 1]}
                            </Typography>
                        )}
                        {text[modalNum - 1] && (
                            <Typography variant="title-sm-regular">
                                {text[modalNum - 1]}
                            </Typography>
                        )}
                    </div>
                    {form[modalNum - 1] && (
                        <div className={styles.form}>{form[modalNum - 1]}</div>
                    )}
                    <div className={styles.actionBtns}>
                        {firstBtnText[modalNum - 1] && (
                            <Button
                                styleType="primary"
                                onClick={() => {
                                    nextModal();
                                }}
                            >
                                <Typography variant="text-md-semibold">
                                    {firstBtnText[modalNum - 1]}
                                </Typography>
                            </Button>
                        )}
                        {secondBtnText[modalNum - 1] && (
                            <Button
                                styleType="tertiary"
                                onClick={
                                    school === ''
                                        ? () => {
                                              if (modalNum === 2) {
                                                  handleIsPrivate(true);
                                              }

                                              nextModal(true);
                                          }
                                        : () => {}
                                }
                            >
                                <Typography variant="text-md-semibold">
                                    {secondBtnText[modalNum - 1]}
                                </Typography>
                            </Button>
                        )}
                    </div>
                    {modalNum > 1 && modalNum <= 4 && (
                        <ArrowLeft
                            className={styles.backIcon}
                            onClick={prevModal}
                        />
                    )}
                </div>
            )}
            {modalNum > 4 && (
                <LoadingModal
                    header={'Perfecto!'}
                    text={'Let’s get you landed to the main stage.'}
                    setPageLoader={setPageLoader}
                />
            )}
        </>
    );
};
