import React from 'react';
import styles from './CircularProgressBar.module.scss';

interface CircularProgressBarProps {
    percentage: number;
    color?: any;
}

const CircularProgressBar: React.FC<CircularProgressBarProps> = ({
    percentage,
    color,
}) => {
    const getColor = (): string => {
        if (percentage >= 90) {
            return '#4CAF50'; // Green shade (100% - 90%)
        } else if (percentage >= 80) {
            return '#8BC34A'; // Light green shade (89% - 80%)
        } else if (percentage >= 60) {
            return '#FFC107'; // Amber shade (79% - 60%)
        } else if (percentage >= 40) {
            return '#FF9800'; // Orange shade (59% - 40%)
        } else if (percentage >= 20) {
            return '#FF5722'; // Deep orange shade (39% - 20%)
        } else {
            return '#F44336'; // Red shade (19% - 0%)
        }
    };
    return (
        <div
            className={styles.uiWidgets}
            style={{
                borderColor: `${color ? color : getColor()}`,
            }}
        >
            <div className={styles.ScorePrent}>
                <p className={color ? styles.dificulty : styles.score}>
                    {' '}
                    {!color ? 'Score' : 'deficulty'}
                </p>

                <div className={styles.uiValues}>
                    {percentage}
                    {!color ? '%' : ' '}
                </div>
            </div>
        </div>
    );
};

export default CircularProgressBar;
