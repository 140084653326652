import React, { useState } from 'react';
import {
    Courses_available as courses,
    Levels_available as levels,
} from '../../../data/constant';
import { yearlyRatePerCourse } from '../../../data';

import { Link } from 'react-router-dom';
import { ReactComponent as ArrowDown } from '../../../assets/icons/arrowDown.svg';
import { ReactComponent as ArrowRight } from '../../../assets/icons/arrowRight.svg';
import { ReactComponent as ArrowUp } from '../../../assets/icons/arrowUp.svg';
import { ReactComponent as OpenBook } from '../../../assets/icons/openBook.svg';
import { Button, Typography } from '../../../common';
import { PaymentModal } from '../PaymentModal';
import { ThankYouModal } from '../ThankYouModal';
import SelectionItem from './SelectionItem';

import styles from './CourseSelection.module.scss';

export const CourseSelection: React.FC = () => {
    const [totalCost, setTotalCost] = useState(0);
    const [dropdownOpened, setDropdownOpened] = useState(false);
    const [modalOneOpened, setModalOneOpened] = useState(false);
    const [modalTwoOpened, setModalTwoOpened] = useState(false);

    const handleDropdown = () => {
        setDropdownOpened((prevVal) => !prevVal);
    };
    const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setTotalCost((prevVal) => prevVal + yearlyRatePerCourse);
        } else if (!e.target.checked) {
            setTotalCost((prevVal) => prevVal - yearlyRatePerCourse);
        }
    };
    const handleModalOneOpen = () => {
        setModalOneOpened(true);
    };
    const handleModalTwoOpen = () => {
        setModalOneOpened(false);
        setModalTwoOpened(true);
    };
    const handleModalsClose = () => {
        setModalOneOpened(false);
        setModalTwoOpened(false);
    };

    return (
        <div className={styles.courseSelectionContainer}>
            <Typography variant="title-md-semibold">Add courses</Typography>
            <div className={styles.courseSelectionDropdownMenu}>
                <div
                    className={styles.courseSelectionDropdown}
                    onClick={handleDropdown}
                >
                    <Typography variant="text-md-regular">
                        Courses I need for Learn section
                    </Typography>
                    {dropdownOpened ? <ArrowUp /> : <ArrowDown />}
                </div>
                <div
                    className={`${
                        dropdownOpened
                            ? styles.courseSelectionMenu
                            : styles.closed
                    }`}
                >
                    {levels.map((level, i) => (
                        <div key={i}>
                            <div className={styles.courseSelectionMenuLevel}>
                                <OpenBook />
                                <Typography variant="text-md-semibold">
                                    {level}
                                </Typography>
                            </div>
                            {courses.map((course, i) => (
                                <SelectionItem
                                    name={course}
                                    selectionFn={handleFormChange}
                                    key={i}
                                />
                            ))}
                        </div>
                    ))}
                </div>
            </div>
            <div className={styles.courseSelectionTotal}>
                <Typography variant="text-md-medium">Total</Typography>
                <Typography variant="title-sm-regular">
                    BDT {totalCost.toFixed(2)}
                </Typography>
                <Button
                    styleType="primary"
                    className={styles.continueButton}
                    onClick={handleModalOneOpen}
                >
                    <Typography variant="text-md-semibold" color="white">
                        Proceed to payment
                    </Typography>
                </Button>
            </div>
            <Link className={styles.courseSelectionTrial} to="/dashboard">
                <Typography variant="text-md-medium">
                    Or continue with the free trial
                </Typography>
                <ArrowRight />
            </Link>
            {modalOneOpened ? (
                <PaymentModal
                    closeFn={handleModalsClose}
                    nextFn={handleModalTwoOpen}
                    total={totalCost}
                />
            ) : null}
            {modalTwoOpened ? (
                <ThankYouModal closeFn={handleModalsClose} />
            ) : null}
        </div>
    );
};
