import React from 'react';

import { Navbar } from '../../components';
import { CourseSelection } from '../../components/Premium/CourseSelection';
import { Header } from '../../components/Premium/Header';
import { InfoGrid } from '../../components/Premium/InfoGrid';

import styles from './Premium.module.scss';

export const Premium: React.FC = () => {
    return (
        <div className={styles.premiumContainer}>
            <div className={styles.fadeInAnimation}>
                <Navbar loggedIn={true} />
                <Header />
                <InfoGrid />
                <CourseSelection />
            </div>
        </div>
    );
};
