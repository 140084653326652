import React from 'react';

import { Typography } from '../../../common';

import styles from './Header.module.scss';

export const Header: React.FC = () => {
    return (
        <div className={styles.headerContainer}>
            <Typography
                variant="title-lg-medium"
                localClassNames={styles.headerText}
            >
                Mock papers, personalized.
            </Typography>
        </div>
    );
};
