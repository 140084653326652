import React, { useEffect, useState } from 'react';

import { Navbar, PageTitle } from '../../components';
import {
    GeneratedPapers,
    Header,
    InstructionsGrid,
    PaperGeneration,
} from '../../components/PersonalizedPapers';

import styles from './PersonalizedPapers.module.scss';

export const PersonalizedPaper: React.FC = () => {
    const [generationSubmissions, setGenerationSubmissions] = useState<
        string[]
    >(['']);
    useState<boolean>(false);

    const handleGeneration = (mockPaperLabel: string) => {
        setGenerationSubmissions((prevVal) => [...prevVal, mockPaperLabel]);
    };

    useEffect(() => {
        setGenerationSubmissions([]);
    }, []);

    return (
        <div className={styles.personlizedPapersContainer}>
            <div className={styles.personlizedPapers}>
                <Navbar loggedIn={true} />
                <div>
                    <PageTitle back_to={'/practice'} title={'mocks'} />
                    <Header />
                </div>
                <PaperGeneration onGenerate={handleGeneration} />
                <InstructionsGrid />
                <GeneratedPapers
                    generationSubmissions={generationSubmissions}
                />
            </div>
        </div>
    );
};
