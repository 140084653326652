import React from 'react';

import { Navbar, SettingsHeader, SettingsTabs } from '../../components';

import styles from './Settings.module.scss';

export const Settings: React.FC = () => {
    return (
        <div className={styles.settingsContainer}>
            <div className={styles.settings}>
                <Navbar loggedIn={true} />
                <SettingsHeader />
                <SettingsTabs />
            </div>
        </div>
    );
};
