import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, PageLink, Typography } from '../../common';
import { Navbar } from '../../components';

import NotFoundLogo from '../../assets/404.svg';
import ChevronBIcon from '../../assets/icons/chevron-black.svg';

import styles from './Error.module.scss';

export const Error404NotFound: React.FC = () => {
    const navigate = useNavigate();

    return (
        <>
            <Navbar loggedIn={true} />
            <div className={styles.mainContainer}>
                <img
                    src={NotFoundLogo}
                    alt="not-found"
                    className={styles.notFoundLogo}
                />
                <div className={styles.warnings}>
                    <Typography
                        variant="text-md-semibold"
                        color="blue-primary-700"
                        localClassNames={styles.errortype}
                    >
                        404 error
                    </Typography>
                    <Typography
                        variant="title-xl-semibold"
                        color="grey-900"
                        localClassNames={styles.warning}
                    >
                        Oops, looks like a dead end.
                    </Typography>
                    <Typography
                        variant="text-xl-regular"
                        color="grey-600"
                        localClassNames={styles.reason}
                    >
                        Sorry, the page you are looking for doesn`t exist or has
                        been moved. Try other options from below.
                    </Typography>
                    <div className={styles.buttons}>
                        <Button
                            styleType="secondary"
                            className={styles.button}
                            onClick={() => navigate('..', { relative: 'path' })}
                        >
                            <img src={ChevronBIcon} alt="go-back"></img>
                            <Typography
                                variant="text-lg-semibold"
                                color="black"
                            >
                                Go back
                            </Typography>
                        </Button>
                        <PageLink to="/learn">
                            <Button
                                styleType="primary"
                                className={styles.button}
                            >
                                <Typography
                                    variant="text-lg-semibold"
                                    color="white"
                                >
                                    Dashboard
                                </Typography>
                            </Button>
                        </PageLink>
                    </div>
                </div>
            </div>
        </>
    );
};
