import React, { useRef, useState } from 'react';

import { Typography } from '../../../../common';

import styles from './SelectionItem.module.scss';

export interface Props {
    name: string;
    selectionFn: any;
}

export const SelectionItem: React.FC<Props> = ({
    name,
    selectionFn,
}: Props) => {
    const courseItem = useRef<HTMLInputElement>(null);
    const [selected, setSelected] = useState(false);

    const handleSelection = () => {
        if (courseItem.current) {
            setSelected(courseItem.current.checked);
        }
    };

    return (
        <div
            className={styles.courseSelectionMenuItem}
            onClick={handleSelection}
        >
            <label htmlFor={name}>
                <Typography variant="text-md-regular">
                    {`${name.charAt(0).toUpperCase()}${name.substring(1)}`}
                </Typography>
            </label>
            <input
                type="checkbox"
                id={name}
                name={name}
                value={name}
                checked={selected}
                ref={courseItem}
                onClick={selectionFn}
            ></input>
        </div>
    );
};

export default SelectionItem;
