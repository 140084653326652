import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReverseArrowIcon } from '../../../assets';
import { Button, Typography } from '../../../common';
import { Navbar, PageTitle, VideoPlayer } from '../../../components';
import { useGetUserCourses } from '../../../features/auth/hooks';
import {
    checkisUserPremium,
    ContentItem,
    getLesson,
    getSublesson,
    VideoLecture,
} from '../../../data';
import {
    MCQComponent,
    OBulletsComponent,
    ParagraphComponent,
    TitleComponent,
    UBulletsComponent,
} from './Content';
import styles from './SubLesson.module.scss';

export const SubLesson: React.FC = () => {
    const { course, chapter, lesson, sublesson } = useParams();
    const navigate = useNavigate();
    const currentLesson = getLesson(course, chapter, lesson);
    const currentSubLesson = getSublesson(course, chapter, lesson, sublesson);
    const [content, setContent] = useState<ContentItem[] | undefined>([]);
    const userCourses = useGetUserCourses();

    const renderVideo = (content?: ContentItem[]) => {
        const video = content?.find(
            (item): item is VideoLecture => item.type === 'video'
        );

        if (video) {
            return (
                <VideoPlayer localClassName={styles.video} url={video.url} />
            );
        }
    };

    useEffect(() => {
        if (!currentSubLesson) {
            navigate('/not-found');
        } else {
            setContent(currentSubLesson.content);
        }
    }, [currentSubLesson, navigate]);

    useEffect(() => {
        if (!checkisUserPremium(userCourses, course, currentSubLesson?.free)) {
            navigate('/premium');
        }
    }, [userCourses, currentSubLesson, course, navigate]);

    return (
        <div className={styles.pageContainer}>
            <Navbar loggedIn={true} />
            <div className={styles.mainContainer}>
                <PageTitle
                    title={course?.toUpperCase()}
                    back_to={`/learn/${course}/chapters/${chapter}/lessons`}
                />
                <Typography
                    variant="title-md-medium"
                    color="grey-gradient"
                    alignment="center"
                >
                    {currentSubLesson?.title}
                </Typography>
                {renderVideo(content)}
                <div className={styles.contentContainer}>
                    {content?.map((item, idx) => {
                        switch (item.type) {
                            case 'title':
                                return <TitleComponent key={idx} {...item} />;
                            case 'paragraph':
                                return (
                                    <ParagraphComponent key={idx} {...item} />
                                );
                            case 'o_bullets':
                                return (
                                    <OBulletsComponent key={idx} {...item} />
                                );
                            case 'u_bullets':
                                return (
                                    <UBulletsComponent key={idx} {...item} />
                                );
                            case 'mcq':
                                return <MCQComponent key={idx} {...item} />;
                            default:
                                return null;
                        }
                    })}
                </div>
                <div className={styles.endContainer}>
                    <Typography variant="title-md-medium" alignment="center">
                        End to a cool lesson right there!
                    </Typography>
                    <div className={styles.actionBtns}>
                        <Button
                            styleType="primary"
                            className={styles.button}
                            onClick={() => {
                                if (
                                    Math.floor(Number(sublesson)) <
                                    (currentLesson?.sublessons?.length ?? 0)
                                )
                                    navigate(
                                        `/learn/${course}/chapters/${chapter}/lessons/${lesson}/sub-lessons/${
                                            Math.floor(Number(sublesson)) + 1
                                        }`
                                    );
                                else {
                                    navigate(
                                        `/learn/${course}/chapters/${chapter}/lessons/${lesson}/recap`
                                    );
                                }
                            }}
                        >
                            <Typography variant="text-md-semibold">
                                {Math.floor(Number(sublesson)) <
                                (currentLesson?.sublessons?.length ?? 0)
                                    ? 'Go to the next section'
                                    : 'Recap the lesson'}
                            </Typography>
                        </Button>
                        <Button
                            styleType="tertiary"
                            className={styles.button}
                            onClick={() =>
                                navigate(
                                    `/learn/${course}/chapters/${chapter}/lessons`
                                )
                            }
                        >
                            <img src={ReverseArrowIcon} alt="go-back"></img>
                            <Typography variant="text-md-semibold">
                                Lessons Page
                            </Typography>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
