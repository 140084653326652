import React, { useEffect, useMemo, useState } from 'react';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Typography } from '../../../common';
import { Navbar, PageTitle } from '../../../components';
import { Paper, papers, Subject, validatePaper } from '../../../data';
import styles from './index.module.scss';

export const PaperPage: React.FC = () => {
    const location = useLocation();
    const params = useParams<{
        curriculum: string;
        level: string;
        subject: string;
        paperIndex: string;
    }>();
    const navigate = useNavigate();

    const [filteredSubject, setFilteredSubject] = useState<Subject>();
    const [filteredPapers, setFilteredPapers] = useState<Paper[]>([]);
    const [selectedPaper, setSelectedPaper] = useState<Paper>();
    const [paperRecs, setPaperRecs] = useState<Paper[]>([]);
    const randomIndexes: number[] = useMemo(() => {
        const selectedIndex: number =
            Math.floor(Number(params.paperIndex)) > 0
                ? Math.floor(Number(params.paperIndex))
                : 1;
        const arr: number[] = [];

        while (
            arr.length <=
            (filteredPapers.length < 5 ? filteredPapers.length : 4)
        ) {
            const randomNum: number = Math.floor(
                Math.random() * filteredPapers.length
            );

            if (randomNum !== selectedIndex && !arr.includes(randomNum))
                arr.push(randomNum);
        }

        return arr;
    }, [filteredPapers.length, params.paperIndex]);
    // const randomIndexes: number[] = useMemo(() => {
    //     const arr: number[] = [];

    //     while (
    //         arr.length <=
    //         (filteredPapers.length < 5 ? filteredPapers.length : 4)
    //     ) {
    //         const randomNum: number = Math.floor(
    //             Math.random() * filteredPapers.length
    //         );

    //         if (randomNum !== state.index && !arr.includes(randomNum))
    //             arr.push(randomNum);
    //     }

    //     return arr;
    // }, [filteredPapers.length, state.index]);

    useEffect(() => {
        if (
            params.curriculum &&
            params.level &&
            params.subject &&
            params.paperIndex &&
            validatePaper(
                params.curriculum,
                params.level,
                params.subject,
                params.paperIndex
            )
        ) {
            setFilteredSubject(
                papers[params.curriculum.toLowerCase()][
                    params.level.toLowerCase()
                ][params.subject.toLowerCase()]
            );
        } else {
            navigate('/not-found');
        }
    }, [params, navigate]);

    useEffect(() => {
        setFilteredPapers(filteredSubject?.papers ?? []);
    }, [filteredSubject]);

    useEffect(() => {
        setSelectedPaper(filteredPapers[Math.floor(Number(params.paperIndex))]);
    }, [filteredPapers, params.paperIndex]);

    useEffect(() => {
        setPaperRecs(randomIndexes.map((index) => filteredPapers[index]));
    }, [randomIndexes, filteredPapers]);

    // useEffect(() => {
    //     setFilteredSubject(
    //         papers[state.curriculum.toLowerCase()][state.level.toLowerCase()][
    //             state.subject.toLowerCase()
    //         ]
    //     );
    //     setFilteredPapers(filteredSubject?.papers ?? []);
    //     setSelectedPaper(filteredPapers[Math.floor(Number(state.index))]);
    // }, [state, filteredSubject, filteredPapers]);

    // useEffect(() => {
    //     setPaperRecs(randomIndexes.map((index) => filteredPapers[index]));
    // }, [randomIndexes, filteredPapers]);

    return (
        <div>
            <Navbar loggedIn={true} />
            <div className={styles.mainContainer}>
                <PageTitle
                    back_to={`/papers/${params.curriculum?.toLowerCase()}/${params.level?.toLowerCase()}/${params.subject?.toLowerCase()}`}
                    title={'PAST PAPER'}
                />
                <div className={styles.header}>
                    <div className={styles.ButtonSection}>
                        <div>
                            <div>
                                <p className={styles.Text}>
                                    {`${
                                        params.subject &&
                                        params.subject.charAt(0).toUpperCase()
                                    }${
                                        params.subject &&
                                        params.subject.toLowerCase().slice(1)
                                    }`}{' '}
                                    {filteredSubject?.subjectcode}
                                </p>
                                <Typography
                                    variant="title-sm-medium"
                                    color="white"
                                >
                                    {selectedPaper?.session}{' '}
                                    {selectedPaper?.year} - Paper{' '}
                                    {selectedPaper?.variant}
                                </Typography>
                            </div>
                            <div className={styles.btnSection}>
                                <Button
                                    styleType="primaryDark"
                                    data-aos="fade-up"
                                    data-aos-delay="400"
                                    onClick={() =>
                                        navigate(
                                            `${location.pathname}/view-paper`
                                        )
                                    }
                                >
                                    <Typography
                                        variant="text-sm-semibold"
                                        color="white"
                                    >
                                        View Paper
                                    </Typography>
                                </Button>
                                <Button
                                    styleType="primaryDark"
                                    data-aos="fade-up"
                                    data-aos-delay="400"
                                    onClick={() =>
                                        navigate(
                                            `${location.pathname}/mark-scheme`
                                        )
                                    }
                                >
                                    <Typography
                                        variant="text-sm-semibold"
                                        color="white"
                                    >
                                        View Mark Scheme
                                    </Typography>
                                </Button>
                                <Button
                                    styleType="primaryDark"
                                    data-aos="fade-up"
                                    data-aos-delay="400"
                                    onClick={() =>
                                        navigate(
                                            `${location.pathname}/split-view`
                                        )
                                    }
                                >
                                    <Typography
                                        variant="text-sm-semibold"
                                        color="white"
                                    >
                                        View Both
                                    </Typography>
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className={styles.statStyle}>
                        <div
                            className={styles.graphStyle}
                            data-aos="fade-up"
                            data-aos-delay="400"
                        >
                            <div>
                                <div
                                    id={styles.criclePrent}
                                    className={styles['circular-progress-bar']}
                                >
                                    <CircularProgressbarWithChildren
                                        value={
                                            Math.floor(
                                                Number(
                                                    selectedPaper?.difficulty
                                                )
                                            ) * 10
                                        }
                                        styles={{
                                            path: {
                                                stroke: 'white',
                                            },
                                            text: {
                                                fill: 'white',
                                            },
                                            trail: {
                                                stroke: 'rgba(8, 138, 178,22)',
                                            },
                                        }}
                                    >
                                        <span className={styles['custom-text']}>
                                            Difficulty
                                        </span>
                                        <strong>
                                            {selectedPaper?.difficulty}
                                        </strong>
                                    </CircularProgressbarWithChildren>
                                </div>
                            </div>
                        </div>
                        <div
                            className={styles.graphStyle}
                            data-aos="fade-down"
                            data-aos-delay="400"
                        >
                            <div
                                id={styles.criclePrents}
                                className={styles['circular-progress-bar']}
                            >
                                <CircularProgressbarWithChildren
                                    value={
                                        Math.floor(
                                            Number(selectedPaper?.usefulness)
                                        ) * 10
                                    }
                                    styles={{
                                        path: {
                                            // Path color
                                            stroke: 'white',
                                        },
                                        text: {
                                            // Text color
                                            fill: 'white',
                                        },
                                        trail: {
                                            // Trail color
                                            stroke: 'rgba(8, 138, 178,22)',
                                        },
                                    }}
                                >
                                    <span className={styles['custom-text']}>
                                        Usefulness
                                    </span>
                                    <strong>{selectedPaper?.usefulness}</strong>
                                </CircularProgressbarWithChildren>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.container}>
                    <p className={styles.Related}>Related Papers</p>
                    <div className={styles.paperRecs}>
                        {paperRecs.map((paper: Paper, i) => (
                            <div
                                className={styles.paperRec}
                                onClick={() =>
                                    navigate(
                                        `/papers/${params.curriculum}/${params.level}/${params.subject}/${randomIndexes[i]}`
                                    )
                                }
                                key={i}
                            >
                                <Typography variant="text-md-regular">
                                    {`${
                                        params.subject &&
                                        params.subject.charAt(0).toUpperCase()
                                    }${
                                        params.subject &&
                                        params.subject.toLowerCase().slice(1)
                                    }`}{' '}
                                    {filteredSubject?.subjectcode} -{' '}
                                    {paper?.session} {paper?.year} - Paper{' '}
                                    {paper?.variant}
                                </Typography>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
