import React, { useState } from 'react';
import classNames from 'classnames';

import { Typography } from '../../../common';
import { UserDetailsTab, UserPasswordTab } from '../../Settings';

import styles from './SettingsTabs.module.scss';

type TabNumbers = 1 | 2 | 3;

export const SettingsTabs: React.FC = () => {
    const [tab, setTab] = useState<TabNumbers>(1);

    const handleTabSelection = (tabNumber: TabNumbers) => {
        setTab(tabNumber);
    };

    return (
        <div className={styles.settingsTabsContainer}>
            <div className={styles.settingsTabs}>
                <div
                    className={classNames(
                        styles.settingTab,
                        tab === 1 && styles.selected
                    )}
                    onClick={() => handleTabSelection(1)}
                >
                    <Typography variant="text-sm-semibold">
                        My details
                    </Typography>
                </div>
                <div
                    className={classNames(
                        styles.settingTab,
                        tab === 2 && styles.selected
                    )}
                    onClick={() => handleTabSelection(2)}
                >
                    <Typography variant="text-sm-semibold">Password</Typography>
                </div>
                {/* <div
                    className={classNames(
                        styles.settingTab,
                        tab === 3 && styles.selected
                    )}
                    onClick={() => handleTabSelection(3)}
                >
                    <Typography variant="text-sm-semibold">Courses</Typography>
                </div> */}
            </div>
            <div className={styles.settingsTabContent}>
                {tab === 1 && <UserDetailsTab />}
                {tab === 2 && <UserPasswordTab />}
                {/* {tab === 3 && 'TAB 3: Courses'} */}
            </div>
        </div>
    );
};
