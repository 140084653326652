import React from 'react';

import { Button, Typography } from '../../../../../common';
import { X } from '../../../../../assets/icons';

import styles from './QuitQuizModal.module.scss';

interface EndQuizProps {
    onEndQuiz: () => void;
    onCancel: () => void;
}

export const QuitQuizModal: React.FC<EndQuizProps> = ({
    onEndQuiz,
    onCancel,
}) => {
    const handleEndQuiz = () => {
        onEndQuiz();
    };

    const handleCancel = () => {
        onCancel();
    };

    return (
        <div className={styles.modal}>
            <div className={styles.modalBackground} onClick={handleCancel} />
            <div className={styles.modalContainer}>
                <X className={styles.x} onClick={handleCancel} />
                <Typography
                    localClassNames={styles.quiz}
                    variant="title-md-semibold"
                    color="black"
                >
                    Are you sure you want to end the quiz?
                </Typography>
                <div className={styles.buttons}>
                    <Button styleType="primary" onClick={handleEndQuiz}>
                        <Typography variant="text-md-medium">
                            End quiz
                        </Typography>
                    </Button>
                    <Button styleType="tertiary" onClick={handleCancel}>
                        <Typography variant="text-md-medium">Cancel</Typography>
                    </Button>
                </div>
            </div>
        </div>
    );
};
