import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as HexaBetaLogo } from '../../assets/hexabetalogo.svg';
import { ReactComponent as Bars } from '../../assets/icons/bars.svg';
import { ReactComponent as X } from '../../assets/icons/x.svg';
import { Button, Typography } from '../../common';
import styles from './HomeNavbar.module.scss';

const HomeNavbar: React.FC = () => {
    const [isNavActive, setNavActive] = useState(false);

    const handleScroll = () => {
        const navbar = document.querySelector(`.${styles.navContainerMain}`);
        if (navbar && window.scrollY > 1550) {
            navbar.classList.add(styles.scrolled);
        } else if (navbar) {
            navbar.classList.remove(styles.scrolled);
        }
    };

    const toggleNavbar = () => {
        setNavActive(!isNavActive);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('click', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.addEventListener('click', handleScroll);
        };
    }, []);

    return (
        <div
            className={`${styles.navContainerMain} ${
                isNavActive ? styles.active : ''
            }`}
        >
            <div className={styles.navContainer}>
                <div className={styles.navLogo}>
                    <Link className={styles.hexaBetaLogo} to="/">
                        <HexaBetaLogo />
                        {/* <img src={HexaBetaLogo} alt="Hexa Beta Logo" /> */}
                    </Link>
                </div>
                <div className={styles.navBar}>
                    {/* <Link className={styles.navLink} to="/about">
                        <Typography variant="text-sm-semibold">
                            About Us
                        </Typography>
                    </Link>
                    <Link className={styles.navLink} to="/contact">
                        <Typography variant="text-sm-semibold">
                            Contact
                        </Typography>
                    </Link> */}
                    <div className={styles.actionButtons}>
                        <Link className={styles.navLink} to="/login">
                            <Button
                                styleType={'outline'}
                                className={styles.loginButton}
                            >
                                <Typography variant="text-md-semibold">
                                    Login
                                </Typography>
                            </Button>
                        </Link>
                        <Link className={styles.navLink} to="/signup">
                            <Button
                                styleType="primary"
                                className={styles.continueButton}
                            >
                                <Typography variant="text-md-semibold">
                                    Create an account
                                </Typography>
                            </Button>
                        </Link>
                    </div>
                </div>
                <div className={styles.toggle} onClick={toggleNavbar}>
                    {isNavActive ? <X /> : <Bars />}
                </div>
                <div
                    className={`${styles.dropdown} ${
                        isNavActive ? styles.open : ''
                    }`}
                >
                    {/* <div className={styles.actionLinks}>
                        <Link className={styles.navLink} to="/about">
                            <Typography variant="title-xs-semibold">
                                About Us
                            </Typography>
                        </Link>
                        <Link className={styles.navLink} to="/contact">
                            <Typography variant="title-xs-semibold">
                                Contact
                            </Typography>
                        </Link>
                    </div> */}
                    <div className={styles.actionButtons}>
                        <Link className={styles.navLink} to="/login">
                            <Button
                                styleType="outline"
                                className={styles.loginButton}
                            >
                                <Typography variant="text-md-semibold">
                                    Login
                                </Typography>
                            </Button>
                        </Link>
                        <Link className={styles.navLink} to="/signup">
                            <Button
                                styleType="primary"
                                className={styles.continueButton}
                            >
                                <Typography variant="text-md-semibold">
                                    Create an account
                                </Typography>
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeNavbar;
