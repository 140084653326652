import React, { useState } from 'react';
import classNames from 'classnames';

import { Button, Typography } from '../../../common';
import { FeedbackModals } from '../../PersonalizedPapers';

import styles from './GeneratedPapers.module.scss';

export interface Props {
    generationSubmissions: string[];
}

export const GeneratedPapers: React.FC<Props> = ({
    generationSubmissions,
}: Props) => {
    const [modal, setModal] = useState<boolean>(false);

    const handleToggleModal = () => {
        setModal((prevVal) => !prevVal);
    };

    return (
        <div className={styles.generatedPapersContainer}>
            {modal && <FeedbackModals closeFn={handleToggleModal} />}
            <div className={styles.header}>
                <Typography variant="title-xs-semibold">
                    Generating papers
                </Typography>
                <Button
                    styleType="primary"
                    className={styles.btn}
                    onClick={handleToggleModal}
                >
                    <Typography variant="text-sm-semibold">
                        Get feedback on your answers
                    </Typography>
                </Button>
            </div>
            <div className={classNames(styles.generatedPapers)}>
                {generationSubmissions.length !== 0 ? (
                    <div className={styles.submissionsList}>
                        {generationSubmissions.map((submission, i) => (
                            <div className={styles.listItem} key={i}>
                                <Typography variant="text-md-regular">
                                    {submission}
                                </Typography>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className={styles.emptyList}>
                        <Typography variant="text-sm-semibold">
                            Your latest generating paper will appear here. You
                            can access the papers from your mail inbox, after
                            generating a mock paper above.
                        </Typography>
                    </div>
                )}
            </div>
            {/* old UI behaviour on generate */}
            {/* <div
                className={classNames(
                    styles.generatedPapers,
                    generationSubmissions.length !== 0 && styles.inProgressBg
                )}
            >
                {generationSubmissions.length !== 0 ? (
                    <Typography variant="text-sm-medium">
                        Generating mock paper on Thermal Physics, Waves, and
                        Space Physics...
                        <br />
                        Keep an eye on your mail inbox.
                    </Typography>
                ) : (
                    <Typography variant="text-sm-semibold">
                        Your latest generating paper will appear here. You can
                        access the papers from your mail inbox, after generating
                        a mock paper above.
                    </Typography>
                )}
            </div> */}
        </div>
    );
};
