import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';

import { Button, Typography } from '../../../../common';
import { Navbar, PageTitle } from '../../../../components';
import { QuestionModal, QuitQuizModal } from '../Modals';
import { FullScreen } from '../../../../assets';
import { getTopicalQuizzes } from '../../../../data';

import styles from './Casual.module.scss';

export const Casual: React.FC = () => {
    const params = useParams<{
        curriculum: string;
        level: string;
        subject: string;
        topic: string;
    }>();
    const navigate = useNavigate();

    const topicalQuizList = getTopicalQuizzes(
        params.curriculum,
        params.level,
        params.subject,
        params.topic
    );

    const [currentQuestion, setCurrentQuestion] = useState<number>(0);
    const [disableAnswers, setDisableAnswers] = useState<boolean>(false);

    const [selectedAnswer, setSelectedAnswer] = useState<string | null>(null);
    const [correctCount, setCorrectCount] = useState<number>(0);
    const [wrongCount, setWrongCount] = useState<number>(0);

    const [questionModalOpened, setQuestionModalOpened] =
        useState<boolean>(false);
    const [endModalOpened, setEndModalOpened] = useState<boolean>(false);

    const toggleEndModal = () => {
        setEndModalOpened((prevVal) => !prevVal);
    };

    const handleQuitQuiz = () => {
        navigate(
            `/quizzes/casual/${params.curriculum}/${params.level}/${params.subject}`
        );
    };

    const handleQuizEnd = () => {
        handleQuitQuiz();
    };

    const handleAnswerSelect = (answer: string) => {
        // Disable selecting a new answer if one is already selected
        if (answer.toUpperCase() !== selectedAnswer?.toUpperCase()) {
            setDisableAnswers(true);
            setSelectedAnswer(answer);

            // Check if the selected answer is correct
            if (
                answer.toUpperCase() ===
                topicalQuizList[currentQuestion].answer.toUpperCase()
            ) {
                setCorrectCount((count) => count + 1);
            } else {
                setWrongCount((count) => count + 1);
            }
        }
    };

    const handleNextQuestion = () => {
        if (currentQuestion < topicalQuizList.length - 1) {
            setCurrentQuestion((prev) => prev + 1);
            setSelectedAnswer(null);
            setDisableAnswers(false);
        } else {
            handleQuizEnd();
        }
    };

    return (
        <div>
            <Navbar loggedIn={true} />
            <div className={styles.mainContainer}>
                {questionModalOpened && (
                    <QuestionModal
                        chk={setQuestionModalOpened}
                        img={topicalQuizList[currentQuestion].img}
                    />
                )}
                {endModalOpened && (
                    <QuitQuizModal
                        onEndQuiz={handleQuitQuiz}
                        onCancel={toggleEndModal}
                    />
                )}

                <PageTitle title={'quiz'} />
                <div className={styles.titleCard}>
                    <div>
                        <Typography
                            variant="title-sm-medium"
                            color="white"
                            alignment="center"
                        >
                            {params.topic &&
                                params.topic
                                    .split(' ')
                                    .map(
                                        (word) =>
                                            word.charAt(0).toUpperCase() +
                                            word.slice(1).toLowerCase()
                                    )
                                    .join(' ')}
                        </Typography>
                    </div>
                </div>
                <div className={styles.testContainer}>
                    <div className={styles.question}>
                        <div className={styles.scrollContainer}>
                            <img
                                src={topicalQuizList[currentQuestion].img}
                                alt="question photo"
                                className={styles.questionImg}
                            />
                        </div>
                        <FullScreen
                            className={styles.fullScreenIcon}
                            onClick={() => setQuestionModalOpened(true)}
                        />
                    </div>
                    <div className={styles.answer}>
                        <div className={styles.scoreBoard}>
                            <Typography variant="text-lg-semibold">
                                Questions answered - {correctCount + wrongCount}
                            </Typography>
                            <div className={styles.correctIncorrect}>
                                <Typography variant="text-md-regular">
                                    Correct - {correctCount}
                                </Typography>
                                <Typography variant="text-md-regular">
                                    Wrong - {wrongCount}
                                </Typography>
                            </div>
                        </div>
                        <div className={styles.selectionSection}>
                            <Typography
                                variant="text-md-regular"
                                localClassNames={styles.text}
                            >
                                Select your answer
                            </Typography>
                            <div className={styles.answerBtns}>
                                {['A', 'B', 'C', 'D'].map((key) => (
                                    <button
                                        key={key}
                                        className={classNames(
                                            styles.answerBtn,
                                            selectedAnswer?.toUpperCase() !==
                                                key.toUpperCase()
                                                ? styles.deselected
                                                : selectedAnswer?.toUpperCase() ===
                                                  topicalQuizList[
                                                      currentQuestion
                                                  ].answer.toUpperCase()
                                                ? styles.selectedCorrect
                                                : styles.selectedIncorrect
                                        )}
                                        onClick={() =>
                                            handleAnswerSelect(
                                                key.toUpperCase()
                                            )
                                        }
                                        disabled={disableAnswers}
                                    >
                                        {key.toUpperCase()}
                                    </button>
                                ))}
                            </div>
                        </div>
                        <div className={styles.actionBtns}>
                            <Button
                                styleType="tertiary"
                                className={styles.actionBtn}
                                onClick={handleNextQuestion}
                                disabled={!selectedAnswer}
                            >
                                <Typography variant="text-md-medium">
                                    {currentQuestion <
                                    topicalQuizList.length - 1
                                        ? 'Next'
                                        : 'Finish'}
                                </Typography>
                            </Button>
                        </div>
                    </div>
                </div>
                <Button
                    styleType="outlineError"
                    className={styles.end}
                    onClick={toggleEndModal}
                >
                    <Typography variant="text-md-medium">End Quiz</Typography>
                </Button>
            </div>
        </div>
    );
};
