import React from 'react';

import styles from './InfoGrid.module.scss';

import { Link } from 'react-router-dom';
import { ReactComponent as ArrowRight } from '../../../assets/icons/arrowRight.svg';
import { Button, Typography } from '../../../common';

export const InfoGrid: React.FC = () => {
    return (
        <div className={styles.infoGridContainer}>
            <div className={styles.row}>
                <div className={styles.col}>
                    <Typography variant="title-sm-semibold">
                        Learn and Practice. <br />
                        All in one place.
                    </Typography>
                    <Link to="/practice">
                        <Button styleType="transparentLight">
                            <Typography variant="text-sm-medium">
                                Explore the Practice section
                            </Typography>
                            <ArrowRight className={styles.arrow} />
                        </Button>
                    </Link>
                </div>
                <div className={styles.col}>
                    <Typography variant="title-lg-semibold">
                        Study <br />
                        in style.
                    </Typography>
                </div>
            </div>
            <div className={styles.row}>
                <Typography variant="title-md-semibold">
                    Learning, superboosted.
                </Typography>
                <Link to="/learn">
                    <Button styleType="tertiary">
                        <Typography variant="text-lg-semibold">
                            Start now
                        </Typography>
                    </Button>
                </Link>
            </div>
        </div>
    );
};
