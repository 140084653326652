import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button, Typography } from '../../../../common';
import { Navbar, PageTitle } from '../../../../components';
import CircularProgressBar from '../../CustomComponent/CircularProgressBar';
import { FastForward, FlipBackward } from '../../../../assets';

import styles from './Score.module.scss';

export const Score: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { correctParcentage } = location.state || {};

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [score, setScore] = useState(correctParcentage);

    return (
        <>
            <Navbar loggedIn={true} />
            <div className={styles.mainContainer}>
                <PageTitle title="QUIZ" back_to={'/practice'} />
                <div>
                    <CircularProgressBar percentage={score} />
                </div>
                <div>
                    <p
                        className={styles.keep}
                        onClick={() => navigate('/question')}
                    >
                        Keep it going!
                    </p>
                </div>
                <div className={styles.Buttons}>
                    <Button styleType="primary" onClick={() => navigate(-1)}>
                        <FastForward className={styles.icon} />
                        <Typography variant="text-md-semibold">
                            Try another quiz
                        </Typography>
                    </Button>
                    <Button
                        styleType="transparentPrimary"
                        onClick={() => navigate('/practice')}
                    >
                        <FlipBackward className={styles.icon} />
                        <Typography variant="text-md-semibold">
                            Go back to Practice
                        </Typography>
                    </Button>
                </div>
            </div>
        </>
    );
};
