import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { ApiError } from '../../../types';
import {
    bkashNumber,
    currentMockCurriculums,
    currentMockDifficulties,
    currentMockLevels,
    currentMockPapers,
    currentMockSubjects,
    currentMockTopics,
    mockPaperPrice,
} from '../../../data';

import {
    Button,
    Dropdown,
    DropdownSelect,
    DropdownSelection,
    DropdownSelections,
    FormModal,
    Modal,
    Typography,
} from '../../../common';
import { useRequestMutation } from '../../../features/mock/mockApiSlice';
import { useGetCurrentUser } from '../../../features/auth/hooks';

import styles from './PaperGenerationModals.module.scss';

interface Props {
    onGenerate: (mockPaperLabel: string) => void;
    closeFn: () => void;
}

export const PaperGenerationModals: React.FC<Props> = ({
    onGenerate,
    closeFn,
}: Props) => {
    const currentUser = useGetCurrentUser();

    const [modal, setModal] = useState<1 | 2 | 3 | 4 | 5 | 6>(1);

    const [curriculum, setCurriculum] = useState<string>('');
    const [level, setLevel] = useState<string>('');
    const [subject, setSubject] = useState<string>('');
    const [topics, setTopics] = useState<string[]>([]);
    const [difficulty, setDifficulty] = useState<string>('');
    const [paper, setPaper] = useState<string>('');

    const [curriculumsList, setCurriculumsList] = useState<string[]>(['']);
    const [levelsList, setLevelsList] = useState<string[]>(['']);
    const [subjectsList, setSubjectsList] = useState<string[]>(['']);
    const [topicsList, setTopicsList] = useState<string[]>(['']);
    const [difficultiesList, setDifficultiesList] = useState<string[]>(['']);
    const [papersList, setPapersList] = useState<string[]>(['']);

    const [requestMock] = useRequestMutation();

    // const handleNavigatePrev = () => {
    //     if (modal > 1) {
    //         setModal((prevVal) => prevVal - 1);
    //     }
    // };

    const handleGenerateSubmissionLabel = () => {
        onGenerate(
            `Mock paper on ${topics.join(', ').toLowerCase()} - ${
                Date().split(' ')[1]
            } ${Date().split(' ')[2]}${
                Date()
                    .split(' ')[2]
                    .charAt(Date().split(' ')[2].length - 1) === '1'
                    ? 'st'
                    : Date()
                          .split(' ')[2]
                          .charAt(Date().split(' ')[2].length - 1) === '2'
                    ? 'nd'
                    : Date()
                          .split(' ')[2]
                          .charAt(Date().split(' ')[2].length - 1) === '3'
                    ? 'rd'
                    : 'th'
            }, ${Date().split(' ')[3]}`
        ); // e.g., Date() returns "Tue Aug 19 1975 23:15:30 GMT+0200 (CEST)"
    };

    const handleSubmitMockRequest = async () => {
        try {
            const payload = {
                name: currentUser?.firstName,
                email: currentUser?.email,
                phonenumber: currentUser?.phoneNumber,
                curriculum,
                level,
                subject,
                topics,
                difficulty,
                paper,
            };

            await requestMock(payload).unwrap();
            handleGenerateSubmissionLabel();
            handleNavigateNext();
        } catch (err) {
            const error = err as ApiError;
            console.log(error.status);
        }
    };

    const handleNavigateNext = () => {
        // change if condition to modal < 6 once the missing "current level" modal is developed
        if (modal < 5) {
            setModal((prevVal) => (prevVal + 1) as 1 | 2 | 3 | 4 | 5 | 6);
        } else {
            closeFn();
        }
    };

    const handleCurriculumSelection = (selection: DropdownSelection) => {
        setCurriculum(selection.value);
    };

    const handleLevelSelection = (selection: DropdownSelection) => {
        setLevel(selection.value);
    };

    const handleSubjectSelection = (selection: DropdownSelection) => {
        setSubject(selection.value);
    };

    const handleTopicsSelection = (selection: DropdownSelections) => {
        setTopics(selection.values);
    };

    const handleDifficultySelection = (selection: DropdownSelection) => {
        setDifficulty(selection.value);
    };

    const handlePaperSelection = (selection: DropdownSelection) => {
        setPaper(selection.value);
    };

    // useEffect(() => {
    //     console.log(curriculum);
    // }, [curriculum]);
    // useEffect(() => {
    //     console.log(level);
    // }, [level]);
    // useEffect(() => {
    //     console.log(subject);
    // }, [subject]);
    // useEffect(() => {
    //     console.log(topics);
    // }, [topics]);
    // useEffect(() => {
    //     console.log(difficulty);
    // }, [difficulty]);
    // useEffect(() => {
    //     console.log(paper);
    // }, [paper]);

    useEffect(() => {
        setCurriculumsList(currentMockCurriculums);
        setLevelsList(currentMockLevels);
        setSubjectsList(currentMockSubjects);
        setTopicsList(currentMockTopics);
        setDifficultiesList(currentMockDifficulties);
        setPapersList(currentMockPapers);
    }, []);

    return (
        <div className={styles.paperGenerationModals}>
            {modal === 1 && (
                <FormModal
                    header={'Choose your curriculum and level'}
                    formFields={[
                        <Dropdown
                            placeholder={'Select curriculum'}
                            list={curriculumsList}
                            onSelect={handleCurriculumSelection}
                            key={modal}
                        />,
                        <Dropdown
                            placeholder={'Select level'}
                            list={levelsList}
                            onSelect={handleLevelSelection}
                            key={modal}
                            moreComingSoon
                        />,
                    ]}
                    nextFn={() => {
                        if (curriculum !== '' && level !== '') {
                            handleNavigateNext();
                        }
                    }}
                    closeFn={closeFn}
                />
            )}
            {modal === 2 && (
                <FormModal
                    header={'Choose a subject'}
                    formFields={[
                        <Dropdown
                            placeholder={'Select Subject'}
                            list={subjectsList}
                            onSelect={handleSubjectSelection}
                            key={modal}
                            moreComingSoon
                        />,
                    ]}
                    nextFn={() => {
                        if (subject !== '') {
                            handleNavigateNext();
                        }
                    }}
                    closeFn={closeFn}
                />
            )}
            {modal === 3 && (
                <FormModal
                    header={'Customize your paper'}
                    formFields={[
                        <DropdownSelect
                            placeholder={'Select topic'}
                            list={topicsList}
                            onSelect={handleTopicsSelection}
                            key={modal}
                        />,
                        <Dropdown
                            placeholder={'Choose difficulty'}
                            list={difficultiesList}
                            onSelect={handleDifficultySelection}
                            key={modal}
                        />,
                        <Dropdown
                            placeholder={'Select paper'}
                            list={papersList}
                            onSelect={handlePaperSelection}
                            key={modal}
                        />,
                    ]}
                    nextFn={() => {
                        if (
                            topics.length !== 0 &&
                            difficulty !== '' &&
                            paper !== ''
                        ) {
                            handleNavigateNext();
                        }
                    }}
                    closeFn={closeFn}
                />
            )}
            {modal === 4 && (
                <Modal closeFn={closeFn}>
                    <Typography
                        variant="title-md-semibold"
                        localClassNames={styles.leftHeader}
                    >
                        Please follow below instructions for payment procedures
                    </Typography>
                    <div className={styles.paymentInstructions}>
                        <div className={styles.row}>
                            <div className={styles.col}>
                                <Typography variant="text-xl-medium">
                                    bKash <span>BDT {mockPaperPrice}</span> to
                                    the number <span>{bkashNumber}</span> within
                                    12 hours of the request.
                                </Typography>
                            </div>
                        </div>
                        <div className={styles.row}>
                            <div className={styles.col}>
                                <Typography variant="text-xl-medium">
                                    Put your information in the format below:
                                </Typography>
                                <br />
                                <Typography variant="text-xl-medium">
                                    <em>
                                        {
                                            '“Personalized Mock Exam: <Name>, <Registered Email Address>“'
                                        }
                                    </em>
                                </Typography>
                                <br />
                                <Typography variant="text-xl-medium">
                                    Eg.{' '}
                                    <span>
                                        Personalized Mock Exam: Olivia Rhye,
                                        olivia@gmail.com
                                    </span>
                                </Typography>
                            </div>
                        </div>

                        <Typography variant="text-xs-regular">
                            Streamlined online payment options will be
                            incorporated after beta.
                        </Typography>
                    </div>
                    <div
                        className={classNames(
                            styles.actionBtns,
                            styles.justifyLeft
                        )}
                    >
                        <Button
                            styleType="primary"
                            className={styles.doneBtn}
                            onClick={handleSubmitMockRequest}
                        >
                            <Typography variant="text-md-semibold">
                                Done
                            </Typography>
                        </Button>
                        <Button
                            styleType="outlineBlack"
                            className={styles.cancelBtn}
                            onClick={closeFn}
                        >
                            <Typography variant="text-md-semibold">
                                Cancel
                            </Typography>
                        </Button>
                    </div>
                </Modal>
            )}
            {modal === 5 && (
                <Modal closeFn={closeFn}>
                    <div className={styles.textBlock}>
                        <Typography
                            variant="title-lg-semibold"
                            localClassNames={styles.centerHeader}
                        >
                            Perfecto!
                        </Typography>
                        <Typography variant="text-xl-regular">
                            We’ll process your preferences and generate a mock
                            paper to your email inbox in 24-48 hours of payment
                            confirmation.
                        </Typography>
                    </div>
                    <div className={styles.actionBtns}>
                        <Button
                            styleType="primary"
                            onClick={handleNavigateNext}
                        >
                            <Typography variant="text-md-semibold">
                                Understood
                            </Typography>
                        </Button>
                    </div>
                </Modal>
            )}
        </div>
    );
};
