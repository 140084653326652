import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Document, Page } from 'react-pdf';
import { useParams } from 'react-router-dom';
import { ZoomIcon } from '../../../assets';
import { PageTitle } from '../../../components';
import {
    checkisUserPremium,
    getLesson,
    getRelatedQuestionsInfo,
} from '../../../data';
import { useGetUserCourses } from '../../../features/auth/hooks';
import { PaperModal } from '../../Practice/PaperSection/PaperModal';
import { Typography } from '../../../common';
import styles from './RelatedQuestions.module.scss';

export const RelatedQuestions: React.FC = () => {
    const navigate = useNavigate();
    const { course, chapter, lesson } = useParams();
    const [numPages, setNumPages] = useState<number>();
    const [modalOpened, setModalOpened] = useState<boolean>(false);
    const content = getRelatedQuestionsInfo(course, chapter, lesson);
    const userCourses = useGetUserCourses();
    const currentLesson = getLesson(course, chapter, lesson);

    useEffect(() => {
        if (!content || !content.relatedQlink) {
            navigate('/not-found');
        }
    }, [content, navigate]);

    useEffect(() => {
        if (
            !checkisUserPremium(
                userCourses,
                course,
                currentLesson?.free_available
            )
        ) {
            navigate('/premium');
        }
    }, [userCourses, currentLesson, course, navigate]);

    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setNumPages(numPages);
    }

    return (
        <>
            <div className={styles.mainContainer}>
                <PageTitle
                    title="QUESTIONS"
                    back_to={`/learn/${course}/chapters/${chapter}/lessons`}
                />
                <div className={styles.header}>
                    <Typography
                        variant="text-lg-regular"
                        color="white"
                        alignment="center"
                    >
                        {`${course?.charAt(0).toUpperCase()}${course?.slice(
                            1
                        )} - ${content?.curriculum}`}
                    </Typography>
                    <Typography
                        variant="title-sm-medium"
                        color="white"
                        alignment="center"
                    >
                        {content?.title}
                    </Typography>
                </div>

                <div className={styles.pdfSection}>
                    <div
                        className={styles.singlepdf}
                        style={{
                            overflow: 'auto',
                        }}
                    >
                        <Document
                            file={content?.relatedQlink}
                            onLoadSuccess={onDocumentLoadSuccess}
                        >
                            {Array.from(
                                { length: Math.floor(Number(numPages)) },
                                (_, idx) => ++idx
                            ).map((pageNum, i) => (
                                <Page
                                    devicePixelRatio={window.devicePixelRatio}
                                    className={styles.customStyle}
                                    pageNumber={pageNum}
                                    key={i}
                                />
                            ))}
                        </Document>
                    </div>
                </div>
                <div className={styles.fullscreen}>
                    <div className={styles.expandButton}>
                        <img
                            onClick={() => setModalOpened(true)}
                            src={ZoomIcon}
                            alt=""
                        />
                        <p className={styles.text}>full screen view</p>
                    </div>
                </div>
                {modalOpened && (
                    <PaperModal
                        chk={setModalOpened}
                        pdf={content?.relatedQlink}
                    />
                )}
            </div>
        </>
    );
};
