import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    useGetCurrentUser,
    useGetUserCourses,
} from '../../../features/auth/hooks';
import classNames from 'classnames';
import { checkisUserPremium, getLesson } from '../../../data';
import { bitesizedRecaps, Slide } from '../../../data';

import Spline from '@splinetool/react-spline';
import { Button, Typography } from '../../../common';
import { Navbar, PageTitle } from '../../../components';
import { ChevronDown } from '../../../assets';

import styles from './BiteSizedRecap.module.scss';

export const BiteSizedRecap: React.FC = () => {
    const { course, chapter, lesson } = useParams();
    const user = useGetCurrentUser();
    const params = useParams();
    const navigate = useNavigate();
    const currentLesson = getLesson(course, chapter, lesson);
    const userCourses = useGetUserCourses();

    const [slide, setSlide] = useState<number>(1);
    const [recapSlides, setRecapSlides] = useState<Slide[]>([]);

    const handleNextSlide = () => {
        if (slide < recapSlides.length + 1) {
            setSlide((prevVal) => prevVal + 1);
        }
    };

    const handlePrevSlide = () => {
        if (slide > 1) {
            setSlide((prevVal) => prevVal - 1);
        }
    };

    useEffect(() => {
        if (
            !checkisUserPremium(
                userCourses,
                course,
                currentLesson?.free_available
            )
        ) {
            navigate('/premium');
        }
    }, [userCourses, currentLesson, course, navigate]);

    useEffect(() => {
        setRecapSlides(
            bitesizedRecaps[
                `${params.course}:${params.chapter}:${params.lesson}`
            ]
        );
    }, [recapSlides, params]);

    return (
        <div className={styles.subLessonRecapContainer}>
            <Navbar loggedIn={true} />
            <div className={styles.subLessonRecap}>
                <div className={styles.header}>
                    <PageTitle
                        title="bite-sized recap"
                        back_to={`/learn/${params.course}/chapters/${params.chapter}/lessons`}
                    />
                    <Typography variant="title-md-medium">
                        {currentLesson?.description}
                    </Typography>
                </div>
                {slide <= recapSlides.length ? (
                    <div className={styles.slides}>
                        <div className={styles.slide}>
                            <div className={styles.slideBg}>
                                <Spline scene="https://prod.spline.design/DQ9Qsnw1jIu1lHf8/scene.splinecode" />
                            </div>
                            <ChevronDown
                                className={classNames(
                                    styles.icon,
                                    slide <= 1 && styles.disabled
                                )}
                                onClick={handlePrevSlide}
                            />
                            <div className={styles.content}>
                                {/* <div className={styles.slideImg}></div> */}
                                <Typography
                                    variant="title-xs-medium"
                                    localClassNames={styles.slideText}
                                >
                                    {recapSlides[slide - 1].text}
                                </Typography>
                            </div>
                            <ChevronDown
                                className={classNames(
                                    styles.icon,
                                    slide > recapSlides.length &&
                                        styles.disabled
                                )}
                                onClick={handleNextSlide}
                            />
                        </div>
                        <div className={styles.actionBtns}>
                            <Button
                                styleType="tertiary"
                                onClick={() => setSlide(recapSlides.length + 1)}
                            >
                                <Typography variant="text-md-semibold">
                                    Skip recap
                                </Typography>
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div className={styles.finalSlide}>
                        <Typography variant="title-md-semibold">
                            {' '}
                            That marks the end of this lesson. {user?.firstName}
                            , congratulations!
                        </Typography>
                        <div className={styles.actionBtns}>
                            {/* <Button
                                styleType="tertiary"
                                onClick={() =>
                                    navigate(
                                        `/learn/${params.course}/chapters/${
                                            params.chapter
                                        }/lessons/${
                                            Math.floor(Number(params.lesson)) +
                                            1
                                        }/sub-lessons/${params.sublesson}`
                                    )
                                }
                            >
                                <Typography variant="text-md-semibold">
                                    Go to the next lesson
                                </Typography>
                            </Button>
                            <Button
                                styleType="transparentLight"
                                onClick={() =>
                                    navigate(
                                        `/learn/${params.course}/chapters/${params.chapter}/lessons`
                                    )
                                }
                            >
                                <Typography variant="text-md-semibold">
                                    Lesson Page
                                </Typography>
                            </Button> */}
                            <Button
                                styleType="tertiary"
                                className={styles.button}
                                onClick={() =>
                                    navigate(
                                        `/learn/${params.course}/chapters/${params.chapter}/lessons`
                                    )
                                }
                            >
                                <Typography variant="text-md-semibold">
                                    Lessons Page
                                </Typography>
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
