import React from 'react';
import { monthlyRatePerCourse, yearlyRatePerCourse } from '../../../data';

import { Typography } from '../../../common';

import styles from './InfoGrid.module.scss';

export const InfoGrid: React.FC = () => {
    return (
        <div className={styles.infoGridContainer}>
            <div className={styles.gridBox}>
                <Typography variant="title-lg-semibold">
                    Flexibility, unlimited.
                </Typography>
                <Typography variant="title-xs-medium">
                    Curate your study plan - with exact precision to your own
                    personalised needs.
                </Typography>
            </div>
            <div className={styles.gridBox}>
                <Typography variant="title-lg-semibold">
                    BDT {Math.floor(yearlyRatePerCourse / 12)}/month value
                </Typography>
                <div>
                    <Typography variant="title-xs-medium">
                        for each course in the <strong>Learn</strong> section
                    </Typography>
                    <Typography variant="text-md-regular">
                        Billed yearly at BDT {yearlyRatePerCourse}, or monthly
                        rolling payment of BDT {monthlyRatePerCourse}
                    </Typography>
                </div>
            </div>
        </div>
    );
};
