import React, { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { useNavigate, useParams } from 'react-router-dom';
// import starIcon from '../../../assets/Practice/Rating.png';
import { PageTitle } from '../../../components';
import tryIcon from '../../../assets/Practice/zoom.png';
import { Typography } from '../../../common';
import { Paper, papers, Subject, validatePaper } from '../../../data';
import styles from './index.module.scss';
import { PaperModal } from './PaperModal';

const SplitView: React.FC = () => {
    const params = useParams<{
        curriculum: string;
        level: string;
        subject: string;
        paperIndex: string;
    }>();
    const navigate = useNavigate();
    const [numPages, setNumPages] = useState<number>();
    const [numPages2, setNumPages2] = useState<number>();
    const [modalOpened, setModalOpened] = useState<boolean>(false);

    const [filteredSubject, setFilteredSubject] = useState<Subject>();
    const [selectedPaper, setSelectedPaper] = useState<Paper>();

    useEffect(() => {
        if (
            params.curriculum &&
            params.level &&
            params.subject &&
            params.paperIndex &&
            validatePaper(
                params.curriculum,
                params.level,
                params.subject,
                params.paperIndex
            )
        ) {
            setFilteredSubject(
                papers[params.curriculum.toLowerCase()][
                    params.level.toLowerCase()
                ][params.subject.toLowerCase()]
            );
            setSelectedPaper(
                papers[params.curriculum.toLowerCase()][
                    params.level.toLowerCase()
                ][params.subject.toLowerCase()].papers[
                    Math.floor(Number(params.paperIndex))
                ]
            );
        } else {
            navigate('/not-found');
        }
    }, [params, navigate]);

    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setNumPages(numPages);
    }

    function onDocumentLoadSuccess2({ numPages }: { numPages: number }): void {
        setNumPages2(numPages);
    }

    return (
        <>
            <div className={styles.mainContainer}>
                <PageTitle
                    back_to={`/papers/${params.curriculum?.toLowerCase()}/${params.level?.toLowerCase()}/${params.subject?.toLowerCase()}/${params.paperIndex?.toLowerCase()}`}
                    title={'PAST PAPER'}
                />
                <div className={styles.techniques}>
                    <div>
                        <p className={styles.phText}>
                            {`${
                                params.subject &&
                                params.subject.charAt(0).toUpperCase()
                            }${
                                params.subject &&
                                params.subject.toLowerCase().slice(1)
                            }`}{' '}
                            {filteredSubject?.subjectcode}
                        </p>
                        <Typography
                            variant="title-sm-medium"
                            color="white"
                            alignment="center"
                        >
                            {selectedPaper?.session} {selectedPaper?.year} -
                            Paper {selectedPaper?.variant}
                        </Typography>
                    </div>
                </div>

                <div className={styles.QsPaperPrent}>
                    <p className={styles.QsPaper}>Question paper</p>
                    <p className={styles.QsPaper}>Mark Scheme</p>
                </div>

                <div className={styles.pdfSection}>
                    <div
                        className={styles.pdf}
                        style={{
                            overflow: 'auto',
                        }}
                    >
                        <Document
                            file={selectedPaper?.qplink}
                            onLoadSuccess={onDocumentLoadSuccess}
                        >
                            {Array.from(
                                { length: Math.floor(Number(numPages)) },
                                (_, idx) => ++idx
                            ).map((pageNum, i) => (
                                <Page
                                    devicePixelRatio={window.devicePixelRatio}
                                    className={styles.customStyle}
                                    pageNumber={pageNum}
                                    key={i}
                                />
                            ))}
                        </Document>
                    </div>

                    <div
                        className={styles.pdf}
                        style={{
                            overflow: 'auto',
                        }}
                    >
                        <Document
                            file={selectedPaper?.mslink}
                            onLoadSuccess={onDocumentLoadSuccess2}
                        >
                            {Array.from(
                                { length: Math.floor(Number(numPages2)) },
                                (_, idx) => ++idx
                            ).map((pageNum, i) => (
                                <Page
                                    devicePixelRatio={window.devicePixelRatio}
                                    className={styles.customStyle}
                                    pageNumber={pageNum}
                                    key={i}
                                />
                            ))}
                        </Document>
                    </div>
                </div>

                <div className={styles.zoomPrant}>
                    <div className={styles.tryButton}>
                        <img
                            src={tryIcon}
                            alt=""
                            onClick={() => setModalOpened(true)}
                        />
                        <p className={styles.zoom}>full screen view</p>
                    </div>
                </div>
                {modalOpened && (
                    <PaperModal
                        chk={setModalOpened}
                        pdf={selectedPaper?.qplink ?? location.pathname}
                        pdf2={selectedPaper?.mslink ?? location.pathname}
                        split={true}
                    />
                )}
                {/* <div>
                    <div>
                        <p className={styles.helpText}>
                            Help Others on how this paper was
                        </p>
                    </div>
                    <div className={styles.iconHeader}>
                        <div className={styles.iconPrent}>
                            <p className={styles.difficulty}>Difficulty</p>
                            <img src={starIcon} alt="" />
                        </div>
                        <div className={styles.iconPrent}>
                            <p className={styles.difficulty}>Usefulness</p>
                            <img src={starIcon} alt="" />
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    );
};

export default SplitView;
