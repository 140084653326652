import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// import { Paper, papers } from '../../../data/data';
import { papers } from '../../../../data/data';

import { Step1, Step2, Step3 } from '..';
import { quizCurriculums, quizLevels, quizSubjects } from '../../../../data';

import styles from './ModalFlows.module.scss';

interface ModalFlowProps {
    chk: (value: boolean) => void;
    pp: boolean;
}

export const PapersModalFlow: React.FC<ModalFlowProps> = ({
    chk,
    pp,
}: ModalFlowProps) => {
    const [step, setStep] = useState<number>(1);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [selectedMode, setSelectedMode] = useState<string | null>(null);
    const navigate = useNavigate();

    const [curriculum, setCurriculum] = useState<string>('');
    const [level, setLevel] = useState<string>('');
    const [subject, setSubject] = useState<string>('');
    // const [paper, setPaper] = useState<string>('');

    const [curriculumsList, setCurriculumsList] = useState<string[]>(['']);
    const [levelsList, setLevelsList] = useState<string[]>(['']);
    const [subjectsList, setSubjectsList] = useState<string[]>(['']);
    // const [papersList, setPapersList] = useState<string[]>(['']);

    useEffect(() => {
        if (pp) {
            setStep(2);
        } else {
            setStep(1);
        }
    }, [pp]);

    useEffect(() => {
        setCurriculumsList(
            Object.keys(papers.default).map(
                (curriculum) =>
                    `${curriculum.charAt(0).toUpperCase()}${curriculum
                        .slice(1)
                        .toLowerCase()}`
            )
        );
    }, []);

    useEffect(() => {
        if (!(curriculum === '')) {
            setLevelsList(
                Object.keys(papers[curriculum.toLowerCase()]).map(
                    (level) => level.charAt(0).toUpperCase() + level.slice(1)
                )
            );
        }
    }, [curriculum]);

    useEffect(() => {
        if (!(curriculum === '' || level === '')) {
            setSubjectsList(
                Object.keys(
                    papers[curriculum.toLowerCase()][level.toLowerCase()]
                ).map(
                    (subject) =>
                        `${subject.charAt(0).toUpperCase()}${subject
                            .slice(1)
                            .toLowerCase()}`
                )
            );
        }
    }, [curriculum, level]);

    // useEffect(() => {
    //     if (!(curriculum === '' || level === '' || subject === '')) {
    //         const filteredPapers: Paper[] =
    //             papers[curriculum.toLowerCase()][level.toLowerCase()][
    //                 subject.toLowerCase()
    //             ].papers;
    //         const options: string[] = filteredPapers.map(
    //             (paper: Paper) =>
    //                 `${paper.session} ${paper.year} Paper ${paper.variant}`
    //         );

    //         setPapersList(options);
    //     }
    // }, [curriculum, level, subject]);

    const handleNext = (mode: string) => {
        setSelectedMode(mode);
        setStep((prevStep) => prevStep + 1);
    };

    return (
        <div className={styles.modal}>
            <div
                className={styles.modalBackground}
                onClick={() => chk(false)}
            />
            <div className={styles.modalPositioning}>
                {step === 1 && (
                    <Step1 chk={chk} onNext={(mode) => handleNext(mode)} />
                )}
                {step === 2 && (
                    <Step2
                        curriculum={curriculum}
                        level={level}
                        setCurriculum={setCurriculum}
                        setLevel={setLevel}
                        curriculumsList={curriculumsList}
                        levelsList={levelsList}
                        chk={chk}
                        handleNext={() => setStep((prev) => prev + 1)}
                    />
                )}
                {/* {step === 3 && (
                    <Step3
                        value={subject}
                        setValue={setSubject}
                        valuesList={subjectsList}
                        chk={chk}
                        handleNext={() => setStep((prev) => prev + 1)}
                    />
                )}
                {step === 4 && (
                    <Step4
                        value={paper}
                        setValue={setPaper}
                        valuesList={papersList}
                        chk={chk}
                        title="Choose a paper"
                        placeholder="Select paper"
                        handleNext={() => {
                            if (selectedMode === 'casual') {
                                navigate('/question');
                            } else if (selectedMode === 'competitive') {
                                navigate('/measurement');
                            } else {
                                navigate('/paper', {
                                    state: {
                                        curriculum: curriculum,
                                        level: level,
                                        subject: subject,
                                        index: paper,
                                    },
                                });
                            }
                        }}
                    />
                )} */}
                {step === 3 && (
                    <Step3
                        value={subject}
                        setValue={setSubject}
                        valuesList={subjectsList}
                        chk={chk}
                        handleNext={() => {
                            navigate(
                                `/papers/${curriculum}/${level}/${subject}`,
                                {
                                    state: {
                                        curriculum: curriculum,
                                        level: level,
                                        subject: subject,
                                    },
                                }
                            );
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export const QuizzesModalFlow: React.FC<ModalFlowProps> = ({
    chk,
}: ModalFlowProps) => {
    const [step, setStep] = useState<number>(1);
    const [selectedMode, setSelectedMode] = useState<string | null>(null);
    const navigate = useNavigate();

    const [curriculum, setCurriculum] = useState<string>('');
    const [level, setLevel] = useState<string>('');
    const [subject, setSubject] = useState<string>('');
    // const [paper, setPaper] = useState<string>('');

    const [curriculumsList, setCurriculumsList] = useState<string[]>(['']);
    const [levelsList, setLevelsList] = useState<string[]>(['']);
    const [subjectsList, setSubjectsList] = useState<string[]>(['']);
    // const [papersList, setPapersList] = useState<string[]>(['']);

    useEffect(() => {
        setCurriculumsList(quizCurriculums);
        setLevelsList(quizLevels);
        setSubjectsList(quizSubjects);
    }, []);
    const handleNext = (mode: string) => {
        console.log(selectedMode);
        setSelectedMode(mode);
        setStep((prevStep) => prevStep + 1);
    };

    return (
        <div className={styles.modal}>
            <div
                className={styles.modalBackground}
                onClick={() => chk(false)}
            />
            <div className={styles.modalPositioning}>
                {step === 1 && (
                    <Step1 chk={chk} onNext={(mode) => handleNext(mode)} />
                )}
                {step === 2 && (
                    <Step2
                        curriculum={curriculum}
                        level={level}
                        setCurriculum={setCurriculum}
                        setLevel={setLevel}
                        curriculumsList={curriculumsList}
                        levelsList={levelsList}
                        chk={chk}
                        handleNext={() => setStep((prev) => prev + 1)}
                    />
                )}
                {step === 3 && (
                    <Step3
                        value={subject}
                        setValue={setSubject}
                        valuesList={subjectsList}
                        chk={chk}
                        handleNext={() => {
                            navigate(
                                `/quizzes/${selectedMode}/${curriculum}/${level}/${subject}`,
                                {
                                    state: {
                                        mode: selectedMode,
                                        curriculum: curriculum,
                                        level: level,
                                        subject: subject,
                                    },
                                }
                            );
                        }}
                    />
                )}
            </div>
        </div>
    );
};
