import React from 'react';
import classNames from 'classnames';

import { Button, Modal, Typography } from '../../common';
import { ChevronRight } from '../../assets';

import styles from './FormModal.module.scss';

export interface FormModalProps {
    className?: string;
    closeFn: () => void;
    nextFn: () => void;
    header: string;
    formFields: React.ReactNode[];
}

export const FormModal: React.FC<FormModalProps> = ({
    className,
    closeFn,
    nextFn,
    header,
    formFields,
}) => {
    return (
        <div className={classNames(styles.formModal, className)}>
            <Modal closeFn={closeFn}>
                <Typography
                    variant="title-md-semibold"
                    localClassNames={styles.header}
                >
                    {header}
                </Typography>
                <div className={styles.form}>
                    <div
                        className={classNames(
                            styles.formFieldsContainer,
                            formFields.length === 1 && styles.one,
                            formFields.length === 2 && styles.two,
                            formFields.length === 3 && styles.three
                        )}
                    >
                        {formFields.map((formField, i) => (
                            <div className={styles.formField} key={i}>
                                {formField}
                                {(formFields.length === 1 ||
                                    formFields.length === 2) &&
                                    i == formFields.length - 1 && (
                                        <Button
                                            styleType="primary"
                                            className={styles.nextBtn}
                                            onClick={nextFn}
                                        >
                                            <ChevronRight
                                                className={styles.icon}
                                            />
                                        </Button>
                                    )}
                            </div>
                        ))}
                    </div>
                    {formFields.length === 3 && (
                        <Button
                            styleType="primary"
                            className={styles.nextBtn}
                            onClick={nextFn}
                        >
                            <ChevronRight className={styles.icon} />
                        </Button>
                    )}
                </div>
            </Modal>
        </div>
    );
};
