import React from 'react';

import styles from './OptionsGrid.module.scss';

import Spline from '@splinetool/react-spline';
import { Link } from 'react-router-dom';
import { ChevronRight } from '../../../assets';
import { Typography } from '../../../common';

// export interface Props {}

// export const OptionsGrid: React.FC<Props> = ({}: Props)
export const OptionsGrid: React.FC = () => {
    return (
        <div className={styles.optionsGridContainer}>
            <div className={styles.actionBtns}>
                <Link to="/learn" className={styles.btn}>
                    <div className={styles.btnBg}>
                        <Spline scene="https://prod.spline.design/X30gNFsFHwg5Wh4e/scene.splinecode" />
                    </div>
                    <div className={styles.btnGradient}></div>
                    <div className={styles.btnText}>
                        <Typography variant="title-md-medium">Learn</Typography>
                        <div className={styles.btnArrow}>
                            <ChevronRight />
                        </div>
                    </div>
                </Link>
                <Link to="/practice" className={styles.btn}>
                    <div className={styles.btnBg}>
                        <Spline scene="https://prod.spline.design/0fZ1CUdlVEtZZyhC/scene.splinecode" />
                    </div>
                    <div className={styles.btnGradient}></div>
                    <div className={styles.btnText}>
                        <Typography variant="title-md-medium">
                            Practice
                        </Typography>
                        <div className={styles.btnArrow}>
                            <ChevronRight />
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    );
};
