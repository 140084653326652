import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Input, PageLink, Typography } from '../../common';
import { Navbar } from '../../components';
import { useLoginMutation } from '../../features/auth/authApiSlice';
import { setCredentials } from '../../features/auth/userSlice';
import { ApiError } from '../../types';
import styles from './Login.module.scss';

export const Login: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);

    const [login, { isLoading }] = useLoginMutation();
    // const loginMutation = useLoginMutation();
    // const login = useMemo(() => loginMutation[0], [loginMutation]);
    // const isLoading = useMemo(
    //     () => loginMutation[1].isLoading,
    //     [loginMutation]
    // );
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogin = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        try {
            const user = await login({
                email: email,
                password: password,
            }).unwrap();
            dispatch(setCredentials({ user }));
            navigate('/dashboard');
        } catch (err) {
            const apiErr = err as ApiError;
            setError(true);
            console.log(apiErr.status);
        }
    };

    const handleInput = (val: any, type: string) => {
        switch (type) {
            case 'email':
                setEmail(val);
                break;
            case 'password':
                setPassword(val);
                break;
            default:
                return null;
        }
    };

    useEffect(() => {
        setError(false);
    }, [email, password]);

    return (
        <>
            <Navbar loggedIn={false} />
            <div className={styles.loginContainer}>
                <div className={styles.contentContainer}>
                    <Typography variant="title-xl-semibold" color="blue-gdnt">
                        Login
                    </Typography>
                    <Typography variant="text-xl-regular" color="grey">
                        Welcome back! Login to continue
                    </Typography>
                    <div className={styles.formContainer}>
                        <Input
                            inputType="email"
                            required={true}
                            placeholder="Enter your email"
                            inputClassname={styles.emailInput}
                            onChange={(val) => handleInput(val, 'email')}
                            disableValidation={true}
                        />
                        <Input
                            inputType="password"
                            required={true}
                            placeholder="••••••••"
                            strictPasswordLength={false}
                            inputClassname={styles.passwordInput}
                            onChange={(val) => handleInput(val, 'password')}
                        />
                        {error && (
                            <Typography
                                variant="text-sm-regular"
                                color="error-400"
                            >
                                Incorrect email or password
                            </Typography>
                        )}
                        <Button
                            styleType="primary"
                            className={styles.continueButton}
                            onClick={handleLogin}
                            disabled={isLoading}
                        >
                            <Typography
                                variant="text-md-semibold"
                                color="white"
                            >
                                Continue
                            </Typography>
                        </Button>
                    </div>
                    <div className={styles.signupContainer}>
                        <Typography variant="text-sm-regular" color="grey">
                            Don’t have an account?
                        </Typography>
                        <PageLink to="/signup">
                            <Typography
                                variant="text-sm-semibold"
                                color="blue-primary-700"
                            >
                                Sign up
                            </Typography>
                        </PageLink>
                    </div>
                    {/* <PageLink to="/new">
                        <Typography
                            variant="text-sm-semibold"
                            color="blue-primary-700"
                        >
                            Forgot password?
                        </Typography>
                    </PageLink> */}
                </div>
            </div>
        </>
    );
};
