import { apiSlice } from '../../app/apiSlice';

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (credentials) => ({
                url: '/login',
                method: 'POST',
                body: { ...credentials },
            }),
        }),
        signup: builder.mutation({
            query: (credentials) => ({
                url: '/signup',
                method: 'POST',
                body: { ...credentials },
            }),
        }),
        logout: builder.mutation({
            query: () => ({
                url: '/logout',
                method: 'POST',
            }),
        }),
        onboard: builder.mutation({
            query: (onboardData) => ({
                url: '/onboard',
                method: 'POST',
                body: { ...onboardData },
            }),
        }),
        changePassword: builder.mutation({
            query: (newPasswordData) => ({
                url: '/change-password',
                method: 'POST',
                body: { ...newPasswordData },
            }),
        }),
    }),
});

export const {
    useLoginMutation,
    useSignupMutation,
    useLogoutMutation,
    useOnboardMutation,
    useChangePasswordMutation,
} = authApiSlice;
