import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EmailIcon } from '../../assets';
import { Button, Input, PageLink, Typography } from '../../common';
import { Navbar } from '../../components';
import { useSignupMutation } from '../../features/auth/authApiSlice';
import { setCredentials } from '../../features/auth/userSlice';
import { ApiError } from '../../types';
import { SignupKeyboard } from '../../assets';
import styles from './Signup.module.scss';

export const Signup: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [referral, setReferral] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [mobile, setMobile] = useState('');
    const [error, setError] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // const [signup, { isLoading }] = useSignupMutation();
    const [signup, { isLoading }] = useSignupMutation();

    const disableSignup = () => {
        return !email || !password || !firstname || !lastname || !mobile;
    };

    const formatName = (name: string) => {
        return name
            .split(/\s+/)
            .filter(Boolean)
            .map(
                (subname) => subname.charAt(0).toUpperCase() + subname.slice(1)
            )
            .join(' ');
    };

    const handleSignup = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        try {
            const user = await signup({
                email: email,
                password: password,
                firstName: firstname,
                lastName: lastname,
                referral: referral,
                phoneNumber: mobile,
            }).unwrap();
            dispatch(setCredentials({ user }));
            navigate('/learn');
        } catch (err) {
            const error = err as ApiError;
            const status = error.status;
            if (status == 400) {
                setError('Missing required fields. Please fill them up.');
            } else if (status == 409) {
                setError('User already exists. Please log in!');
            } else if (status == 500) {
                setError('Internal server error. Please try again!');
            } else {
                setError('Signup failed. Please try again later.');
            }
        }
    };

    const handleInput = (val: string, type: string) => {
        switch (type) {
            case 'email':
                setEmail(val);
                break;
            case 'password':
                setPassword(val);
                break;
            case 'referral':
                setReferral(val);
                break;
            case 'f_name':
                setFirstname(formatName(val));
                break;
            case 'l_name':
                setLastname(formatName(val));
                break;
            case 'mobile':
                setMobile(val);
                break;
            default:
                return null;
        }
    };

    return (
        <>
            <div className={styles.signupPage}>
                <Navbar loggedIn={false} />
                <div className={styles.formSection}>
                    <div className={styles.formContainer}>
                        <Typography
                            variant="title-md-semibold"
                            color="blue-gdnt"
                            localClassNames={styles.title}
                        >
                            Sign up
                        </Typography>
                        <Typography
                            variant="text-md-regular"
                            color="grey"
                            localClassNames={styles.subtitle}
                        >
                            Welcome! Sign up below to continue.
                        </Typography>

                        <div className={styles.inputContainer}>
                            <Typography
                                variant="text-sm-medium"
                                color="grey-700"
                            >
                                First Name*
                            </Typography>
                            <Input
                                inputType="text"
                                required={true}
                                placeholder="Enter your first name"
                                inputClassname={styles.inputBox}
                                onChange={(val) => handleInput(val, 'f_name')}
                            />
                        </div>
                        <div className={styles.inputContainer}>
                            <Typography
                                variant="text-sm-medium"
                                color="grey-700"
                            >
                                Last Name*
                            </Typography>
                            <Input
                                inputType="text"
                                required={true}
                                placeholder="Enter your last name"
                                inputClassname={styles.inputBox}
                                onChange={(val) => handleInput(val, 'l_name')}
                            />
                        </div>

                        <div className={styles.inputContainer}>
                            <Typography
                                variant="text-sm-medium"
                                color="grey-700"
                            >
                                Email*
                            </Typography>
                            <Input
                                inputType="email"
                                required={true}
                                placeholder="Enter your email"
                                inputClassname={styles.inputBox}
                                onChange={(val) => handleInput(val, 'email')}
                            />
                        </div>
                        {/* {signupType === 'school' && (
                                <div className={styles.inputContainer}>
                                    <Typography
                                        variant="text-sm-medium"
                                        color="grey-700"
                                    >
                                        School Passphrase*
                                    </Typography>
                                    <Input
                                        inputType="text"
                                        required={true}
                                        placeholder="Enter your personalised passphrase"
                                        inputClassname={styles.inputBox}
                                    />
                                </div>
                            )} */}
                        <div className={styles.inputContainer}>
                            <Typography
                                variant="text-sm-medium"
                                color="grey-700"
                            >
                                Mobile Number*
                            </Typography>
                            <Input
                                inputType="tel"
                                required={true}
                                placeholder="Enter your mobile number"
                                inputClassname={styles.inputBox}
                                onChange={(val) => handleInput(val, 'mobile')}
                            />
                        </div>
                        <div className={styles.inputContainer}>
                            <Typography
                                variant="text-sm-medium"
                                color="grey-700"
                            >
                                Password*
                            </Typography>
                            <Input
                                inputType="password"
                                required={true}
                                placeholder="Create a password"
                                inputClassname={styles.inputBox}
                                onChange={(val) => handleInput(val, 'password')}
                            />
                        </div>
                        {/* <div className={styles.inputContainer}>
                            <Typography
                                variant="text-sm-medium"
                                color="grey-700"
                            >
                                Referral Code (if any)
                            </Typography>
                            <Input
                                inputType="text"
                                required={true}
                                placeholder="Enter referral code"
                                inputClassname={styles.inputBox}
                                onChange={(val) => handleInput(val, 'referral')}
                            />
                        </div> */}

                        <Button
                            styleType="primary"
                            className={styles.getStartedButton}
                            onClick={handleSignup}
                            disabled={disableSignup() || isLoading}
                        >
                            <Typography
                                variant="text-md-semibold"
                                color="white"
                            >
                                Get started
                            </Typography>
                        </Button>
                        {error && (
                            <Typography
                                variant="text-sm-regular"
                                color="error-400"
                            >
                                {error}
                            </Typography>
                        )}
                        <div className={styles.loginLink}>
                            <Typography variant="text-sm-regular" color="grey">
                                Already have an account?
                            </Typography>
                            <PageLink to="/login">
                                <Typography
                                    variant="text-sm-semibold"
                                    color="blue-primary-700"
                                >
                                    Log in
                                </Typography>
                            </PageLink>
                        </div>
                    </div>

                    <div className={styles.footer}>
                        <Typography variant="text-sm-regular" color="grey-600">
                            © Hexa Inc 2023
                        </Typography>
                        <div className={styles.footerEmail}>
                            <img src={EmailIcon} alt="Hexa email" />
                            <Typography
                                variant="text-sm-regular"
                                color="grey-600"
                            >
                                <a
                                    className={styles.emailLink}
                                    href="mailto:hexapapers@gmail.com"
                                >
                                    hexapapers@gmail.com
                                </a>
                            </Typography>
                        </div>
                    </div>
                </div>
                <div className={styles.assetSection}>
                    {/* <Spline
                        className={styles.asset}
                        scene="https://prod.spline.design/XxFFaQQjEzAslqgL/scene.splinecode"
                    /> */}
                    <img className={styles.asset} src={SignupKeyboard} />
                    <div className={styles.overlayText}>
                        <Typography variant="text-xl-semibold" color="white">
                            Redefining Education Fundamentally
                        </Typography>
                        <Typography
                            variant="text-md-regular"
                            color="white"
                            alignment="center"
                        >
                            Be one of the first in our pursuit of democratising,
                            digitising, and personalising education.
                        </Typography>
                    </div>
                </div>
            </div>
        </>
    );
};

{
    /* <div className={styles.initialPage}>
                    <Typography variant="title-xl-semibold" color="blue-gdnt">
                        Hey there!
                    </Typography>
                    <Typography
                        variant="title-sm-regular"
                        color="blue-primary-900"
                    >
                        Do you want to sign up individually or through a school?
                    </Typography>
                    <div className={styles.optionButtons}>
                        <Button
                            styleType="primary"
                            className={styles.optionButton}
                            onClick={() => setSignupType('individual')}
                        >
                            <Typography
                                variant="text-md-semibold"
                                color="white"
                            >
                                Individually
                            </Typography>
                        </Button>
                        <Button
                            styleType="secondary"
                            className={styles.optionButton}
                            onClick={() => setSignupType('school')}
                        >
                            <Typography
                                variant="text-md-semibold"
                                color="grey-900"
                            >
                                Through a school
                            </Typography>
                        </Button>
                    </div>
                </div> */
}
