import React, { useState } from 'react';

import styles from './PhysicsBox.module.scss';

import { Link } from 'react-router-dom';
import { ReactComponent as ArrowRight } from '../../../assets/icons/arrowRight.svg';
import { Button, Typography } from '../../../common';
// import { VideoPlayer } from '../../VideoPlayer';

export const PhysicsBox: React.FC = () => {
    const [isModalVisibleVideo, setModalVisibleVideo] = useState(false);

    const openModalVideo = () => {
        setModalVisibleVideo(true);
    };

    const closeModalVideo = () => {
        setModalVisibleVideo(false);
    };

    return (
        <div className={styles.physicsBoxContainer}>
            <div className={styles.secondaryHeader}>
                <Typography variant="title-xs-regular">
                    Featured course <span>from CIE O Levels</span>
                </Typography>
            </div>
            <div className={styles.primaryHeader}>
                <div className={styles.blurb}>
                    <Typography variant="title-xs-regular">Physics</Typography>
                    <Typography variant="title-xs-regular">
                        where magic meets science
                    </Typography>
                </div>
                <div className={styles.actionBtns}>
                    <Link to="/learn">
                        <Button styleType="tertiary">
                            <Typography variant="text-lg-semibold">
                                Start learning today
                            </Typography>
                        </Button>
                    </Link>
                    <Button
                        styleType="transparentLight"
                        onClick={openModalVideo}
                    >
                        <Typography variant="text-lg-medium">
                            Watch the trailer
                        </Typography>
                        <ArrowRight />
                    </Button>
                </div>
            </div>
            {isModalVisibleVideo && (
                <div onClick={closeModalVideo} className={styles.modal}>
                    <div className={styles.videoContainer}>
                        {/* <VideoPlayer
                            localClassName={styles.youtubeEmbed}
                            url="https://hexa-application.s3.us-east-2.amazonaws.com/Trailers/Hexa-CIE-Physics-O-Levels-Trailer.mp4"
                        /> */}
                        <iframe
                            className={styles.youtubeEmbed}
                            // width="560"
                            // height="315"
                            src="https://hexa-content.s3.ap-southeast-1.amazonaws.com/Trailers/CIE+Physics+O+Levels+-+Trailer.mp4"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                        ></iframe>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PhysicsBox;
