import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as PlayIcon } from '../../../assets/icons/play.svg';
import { ChevronBIcon, RobinSirPoster } from '../../../assets';
import { Button, PageLink, Typography } from '../../../common';
import { Navbar, PageTitle, VideoPlayer } from '../../../components';
import { Course, getCourse } from '../../../data';
import styles from './Course.module.scss';

export const CoursePage: React.FC = () => {
    const navigate = useNavigate();
    const { course } = useParams();
    const currentCourse: Course | undefined = getCourse(course);
    useEffect(() => {
        if (!currentCourse) {
            navigate('/not-found');
        }
    }, [currentCourse, course, navigate]);
    return (
        <>
            <Navbar loggedIn={true} />
            <div className={styles.mainContainer}>
                <PageTitle title="LEARN" back_to="/learn" />
                <Typography variant="title-2xl-medium" color="grey-gradient">
                    {course &&
                        course?.charAt(0).toUpperCase() + course?.slice(1)}
                </Typography>
                <Typography variant="title-xs-regular" color="grey-gradient">
                    {currentCourse?.curriculum}
                </Typography>
                <PageLink to="chapters">
                    <Button styleType="primary" className={styles.button}>
                        <PlayIcon />
                        <Typography variant="text-md-semibold" color="white">
                            Start
                        </Typography>
                    </Button>
                </PageLink>
                <Typography variant="title-xs-regular" color="grey-800">
                    {currentCourse?.heading}
                </Typography>
                <VideoPlayer
                    localClassName={styles.video}
                    url={currentCourse?.trailer}
                />
                {/* <div className={styles.timeline}>
                    <div className={styles.numberbox}>
                        <p className={styles.number}>06</p>
                    </div>
                </div> */}
                <div className={styles.instructorPoster}>
                    <img
                        src={RobinSirPoster}
                        alt="robin-sir-poster"
                        className={styles.posterimage}
                    />
                    <div className={styles.details}>
                        <Typography variant="text-xl-regular" color="grey-50">
                            Curriculum designed by
                        </Typography>
                        <Typography variant="title-lg-semibold" color="white">
                            {currentCourse?.instructor.name}
                        </Typography>
                        <Typography variant="text-lg-regular" color="grey-50">
                            {`${currentCourse?.instructor.degree},`}
                        </Typography>
                        <Typography variant="text-lg-regular" color="grey-50">
                            {currentCourse?.instructor.institution}
                        </Typography>
                    </div>
                </div>

                <div className={styles.mockexams}>
                    <div>
                        <p className={styles.mockNumber}>25</p>
                    </div>
                    <div>
                        <Typography
                            variant="title-md-semibold"
                            color="blue-gdnt"
                        >
                            Total custom curated mock exam papers at the end of
                            each lesson. Plus more overall.
                        </Typography>
                    </div>
                </div>

                <Typography
                    variant="title-lg-medium"
                    color="grey-gradient"
                    localClassNames={styles.phrase}
                    alignment="center"
                >
                    ...a beautiful amalgamation of cinematic lecture videos,
                    notes, interactive MCQs, and a fantastic bite-sized recap at
                    the end for each lesson...
                </Typography>
                <div className={styles.endContainer}>
                    <Typography variant="title-xl-medium" alignment="center">
                        What are you waiting for?
                    </Typography>
                    <div className={styles.buttons}>
                        <PageLink to="chapters">
                            <Button
                                styleType="primary"
                                className={styles.button}
                            >
                                <Typography
                                    variant="text-lg-semibold"
                                    color="white"
                                >
                                    Start learning now
                                </Typography>
                            </Button>
                        </PageLink>
                        <PageLink to="/dashboard">
                            <Button
                                styleType="secondary"
                                className={classNames(
                                    styles.button,
                                    styles.gobackbutton
                                )}
                            >
                                <img src={ChevronBIcon} alt="go-back"></img>
                                <Typography
                                    variant="text-lg-semibold"
                                    color="black"
                                >
                                    Go back to dashboard
                                </Typography>
                            </Button>
                        </PageLink>
                    </div>
                </div>
            </div>
        </>
    );
};
