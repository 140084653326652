import React from 'react';
import { useGetCurrentUser } from '../../../features/auth/hooks';
import { bkashNumber, monthlyRatePerCourse } from '../../../data';

import { ReactComponent as X } from '../../../assets/icons/x.svg';
import { Button, Typography } from '../../../common';

import styles from './PaymentModal.module.scss';

export interface Props {
    closeFn: any;
    nextFn?: any;
    total: number;
}

export const PaymentModal: React.FC<Props> = ({
    closeFn,
    nextFn = closeFn,
    total,
}: Props) => {
    const currentUser = useGetCurrentUser();
    return (
        <div className={styles.paymentModal}>
            <div className={styles.paymentModalBg} onClick={closeFn} />
            <div className={styles.paymentModalContainer}>
                <div className={styles.paymentModalContent}>
                    <Typography variant="title-md-semibold">
                        Please follow below instructions for payment procedures
                    </Typography>
                    <div className={styles.paymentInstructions}>
                        <div className={styles.row}>
                            <div className={styles.col}>
                                <Typography variant="text-xl-medium">
                                    bKash{' '}
                                    <span>
                                        BDT {total} (or BDT{' '}
                                        {total ? monthlyRatePerCourse : 0} if
                                        you’re using on a monthly rolling basis)
                                    </span>{' '}
                                    to the number <span>{bkashNumber}</span>
                                </Typography>
                            </div>
                            <div className={styles.col}>
                                <Typography variant="text-xl-medium">
                                    Put your{' '}
                                    <span>
                                        registered name{' '}
                                        {currentUser &&
                                            `[${currentUser.firstName} ${currentUser.lastName}]`}{' '}
                                        and email address{' '}
                                        {currentUser &&
                                            `[${currentUser.email}]`}{' '}
                                    </span>{' '}
                                    in the reference section of the bKash
                                    payment
                                </Typography>
                            </div>
                        </div>
                        <div className={styles.row}>
                            <div className={styles.col}>
                                <Typography variant="text-xl-medium">
                                    Click on <span>Done</span> below, and you’ll
                                    get access to all the premium content soon
                                    after payment verification.
                                </Typography>
                            </div>
                        </div>

                        <Typography variant="text-xs-regular">
                            Streamlined online payment options will be
                            incorporated after beta.
                        </Typography>
                    </div>
                    <div className={styles.actionButtons}>
                        <Button
                            styleType="primary"
                            className={styles.doneBtn}
                            onClick={nextFn}
                        >
                            <Typography variant="text-md-semibold">
                                Done
                            </Typography>
                        </Button>
                        <Button
                            styleType="outlineBlack"
                            className={styles.cancelBtn}
                            onClick={closeFn}
                        >
                            <Typography variant="text-md-semibold">
                                Cancel
                            </Typography>
                        </Button>
                    </div>
                    <X className={styles.closeBtn} onClick={closeFn} />
                </div>
            </div>
        </div>
    );
};
