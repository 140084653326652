/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useLoginMutation } from '../auth/authApiSlice';
import { setCredentials } from '../auth/userSlice';
import { Outlet } from 'react-router-dom';

export const DemoRedirect: React.FC = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const source = searchParams.get('source');
    const [login] = useLoginMutation();

    const demoLogin = async () => {
        try {
            const user = await login({
                email: 'recruiterdemo@hexa.com',
                password: 'recruiterdemo',
            }).unwrap();
            dispatch(setCredentials({ user }));
            navigate('/dashboard');
        } catch (err) {
            navigate('/');
        }
    };

    useEffect(() => {
        if (source && source == 'resume') {
            demoLogin();
        }
    }, [source]);

    return <Outlet />;

    // return source && source == 'resume' ? (
    //     <Navigate to="/dashboard" state={{ from: location }} replace />
    // ) : (
    //     <Navigate to="/home" state={{ from: location }} replace />
    // );
};
